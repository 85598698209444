import React from "react";

import {
  Box,
  InputLabel,
  Typography,
  // Customizable Area Start
  FormControl,
  NativeSelect,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "@adyen/adyen-web/dist/adyen.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import Loader from "../../../components/src/Loader";
// Customizable Area End

import AdyenCheckoutController, {
  Props,
  configJSON,
} from "./AdyenCheckoutController.web";

export default class AdyenCheckout extends AdyenCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Typography variant="h5" align="center" style={webStyle.heading}>
          {configJSON.checkoutText}
        </Typography>
        {this.state.isNote && <Typography color="inherit" data-test-id="note" align="center">{configJSON.successNote}</Typography>}
        <Box sx={webStyle.cardContainer}>
          <Box className="payment-container">
            <div
              ref={this.paymentContainer as React.RefObject<HTMLDivElement>}
              className="payment"></div>
            <div id="dropin-container" />
          </Box>

          <Box style={webStyle.orderDetails}>
            <InputLabel variant="standard">
            {configJSON.orderSummaryHeading}
            </InputLabel>
            <Box sx={webStyle.orderSummaryContainer}>
              <Box sx={webStyle.orderSummarySmallContainer}>
                <Typography style={webStyle.smallHeading}>
                  {configJSON.itemNameText} :
                </Typography>
                <Typography>{configJSON.itemNameValue}</Typography>
              </Box>
              <Box sx={webStyle.orderSummarySmallContainer}>
                <Typography style={webStyle.smallHeading}>
                  {configJSON.itemPriceText} :
                </Typography>
                {this.getAmount()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.formControlContainer}>
          <FormControl style={webStyle.formControl} variant="standard">
            <Typography variant="subtitle2">{configJSON.countryText}</Typography>
            <NativeSelect
              data-test-id="dropdownBtn"
              name="selectedCountry"
              value={this.state.selectedCountry}
              inputProps={{
                name: "selectedCountry",
                id: "uncontrolled-native",
              }}
              onChange={(event) =>
                this.handleSelectDropdown(event.target.value)
              }>
              {this.state.countryList.length > 0 && this.state.countryList.map((country) => (
                <option value={country.code} key={country.id} >
                  {country.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>

          <FormControl style={webStyle.formControl} variant="standard" >
            <Typography variant="subtitle2">{configJSON.currencyText}</Typography>
            <NativeSelect
              data-test-id="dropdownBtnTwo"
              name="processingCurrency"
              value={this.state.processingCurrency}
              inputProps={{
                name: "processingCurrency",
                id: "uncontrolled-native",
              }}
              onChange={(event) =>
                this.selectProcessingCurrency(event.target.value)
              }>
              {this.state.processingCurrencyArr.map((currency, index) => {
                return (
                  <option value={currency} key={index} data-test-id = "currencyItem">
                    {currency}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <Loader loading={this.state.isLoaderVisible} data-test-id="loader" />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  orderSummaryHeading: {
    margin: "7px 0",
    color: "rgb(98, 0, 238)",
  },
  formControl: { width: "200px",margin:"2px" },
  formControlContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    margin: "2px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  heading: { color: "rgb(98, 0, 238)", margin: "10px 0" },
  smallHeading: {
    marginRight: "5px",
    fontWeight: "bold" as "bold",
  },
  orderDetails: { margin: "5px" },
  tableContainer: {
    maxHeight: 440,
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalAddButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeModalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  note: {
    width: "100%" as "100%",
    textAlign: "center" as "center",
  },
  orderSummaryContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "10px",
    margin: "5px",
  },
  orderSummarySmallContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-betwen",
  },
  nativeSelect:{
    marginTop :"1px"
  }
};
// Customizable Area End
