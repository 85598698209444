import React, { useCallback } from "react";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { IRazorProps } from "./types";
import Razorpay6ComponentView from "./Razorpay6ComponentView";
const configJSON = require("./config.js");
import { Box } from "@material-ui/core";
export interface ViewProps extends IRazorProps {
  testID: string;
  handlePayment: () => Promise<void>;
}

const Razorpay6Component: React.FC<IRazorProps> = (props) => {
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(async () => {
    const customerDetails =
      props.history.location.state.data.customerData.data.attributes;
    const options: RazorpayOptions = {
      key: `${configJSON.razorKey}`,
      amount: `${props.history.location.state.data.item.attributes.amount}`,
      currency: `${props.history.location.state.data.item.attributes.currency}`,
      name: `${configJSON.projectNameText}`,
      description: `${configJSON.description}`,
      order_id: `${props.history.location.state.data.item.attributes.razorpay_order_id}`,
      handler: (resp) => {
        props.handleSuccessResponse(resp);
      },
      prefill: {
        name: `${customerDetails.first_name}${" "}${customerDetails.last_name}`,
        email: `${customerDetails.email}`,
        contact: `${customerDetails.full_phone_number}`,
      },
      theme: {
        color: `${configJSON.themeColor}`,
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  const viewProps: ViewProps = {
    testID: "Razorpay6ComponentView",
    handlePayment,
    ...props,
  };

  return (
    <Box data-test-id = "componentContainet">
      <Razorpay6ComponentView {...viewProps} />;
    </Box>
  );
};

export default Razorpay6Component;
