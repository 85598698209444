// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Ccavenueintegration2CartOrders from '../../blocks/ccavenueintegration2/src/Ccavenueintegration2CartOrders.web';
import Ccavenueintegration2CartOrderItem from '../../blocks/ccavenueintegration2/src/Ccavenueintegration2CartItem.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem.web';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders.web';
import Ccavenueintegration2Checkout from '../../blocks/ccavenueintegration2/src/Ccavenueintegration2Checkout.web';
import Ccavenueintegration2Success from '../../blocks/ccavenueintegration2/src/Ccavenueintegration2Success.web';
import Shiprocketintegrate2 from '../../blocks/shiprocketintegrate2/src/ShiprocketIntegrate2.web';
import UpdateAddress from '../../blocks/shiprocketintegrate2/src/UpdateAddress.web';
import TrackOrder from '../../blocks/shiprocketintegrate2/src/TrackOrder.web';
import OrderDetails from '../../blocks/shiprocketintegrate2/src/OrderDetails.web';
import Razorpayintegration6 from '../../blocks/razorpayintegration6/src/Razorpayintegration6.web';
import RazorpayintegrationCheckout from '../../blocks/razorpayintegration6/src/Razorpay6Checkout.web';;
import AdyenIntegration from "../../blocks/adyenintegration/src/AdyenIntegration.web";
import AdyenCheckout from "../../blocks/adyenintegration/src/AdyenCheckout.web"
import EasypostIntegration from "../../blocks/easypostintegration/src/Easypostintegration.web";
import TappaymentsIntegration from '../../blocks/tappaymentsintegration/src/Tappaymentsintegration.web'
import Phonepeintegration1 from '../../blocks/phonepeintegration1/src/Phonepeintegration1.web'
import Phonepeintegration1Status from "../../blocks/phonepeintegration1/src/Phonepeintegration1Status.web"
import Phonepeintgration1Checkout from "../../blocks/phonepeintegration1/src/Phonepeintegration1Checkout.web"
import Cometchatintegration from "../../blocks/cometchatintegration/src/Cometchatintegration.web"
import Kaleyra2 from "../../blocks/kaleyra2/src/Kaleyra2.web"

const routeMap = {
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  Ccavenueintegration2Success: {
    component: Ccavenueintegration2Success,
    path: '/Ccavenueintegration2Success',
  },
  Ccavenueintegration2Checkout: {
    component: Ccavenueintegration2Checkout,
    path: '/Ccavenueintegration2Checkout',
  },

  Ccavenueintegration2CartOrders: {
    component: Ccavenueintegration2CartOrders,
    path: '/Ccavenueintegration2CartOrders',
  },
  Ccavenueintegration2CartOrderItem: {
    component: Ccavenueintegration2CartOrderItem,
    path: '/Ccavenueintegration2CartOrderItem',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },

  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders',
  },

  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem',
  },
  Shiprocketintegrate2: {
    component: Shiprocketintegrate2,
    path: '/Shiprocketintegrate2',
  },
  EasypostIntegration: {
    component: EasypostIntegration,
    path: '/EasypostIntegration'
  },
  UpdateAddress: {
    component: UpdateAddress,
    path: '/UpdateAddress',
  },
  TrackOrder: {
    component: TrackOrder,
    path: '/TrackOrder',
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails',
  },
  Razorpayintegration6: {
    component: Razorpayintegration6,
    path: '/Razorpayintegration6',
  },
  RazorpayintegrationCheckout: {
    component: RazorpayintegrationCheckout,
    path: '/RazorpayintegrationCheckout',
  },

  AdyenIntegration:{
    component: AdyenIntegration,
    path: '/AdyenIntegration',
  },
  AdyenCheckout:{
    component: AdyenCheckout,
    path: '/AdyenCheckout',
  },
  
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
  },

  TappaymentsIntegration: {
    component: TappaymentsIntegration,
    path: "/TappaymentsIntegration",
  },

  Phonepeintgration1Checkout: {
    component: Phonepeintgration1Checkout,
    path: "/Phonepeintgration1Checkout",
  },
  Phonepeintegration1Status: {
    component: Phonepeintegration1Status,
    path: "/Phonepeintegration1Status",
  },
  Phonepeintegration1: {
    component: Phonepeintegration1,
    path: "/Phonepeintegration1",
  },
  Cometchatintegration: {
    component: Cometchatintegration,
    path: "/Cometchatintegration",
  },
  Kaleyra2: {
    component: Kaleyra2,
    path: "/Kaleyra2",
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
