import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import Ccavenueintegration2SuccessController, {
  Props,
  configJSON,
} from "./Ccavenueintegration2SuccessController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class Ccavenueintegration2Success extends Ccavenueintegration2SuccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Box sx={webStyle.successMainContainer}>
            <Typography variant="h5">{configJSON.successText}</Typography>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  successMainContainer: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    justifyContent: "Center",
    alignItems: "center",
    height: "60vh",
    width: "100%",
    paddingBottom: "30px",
    background: "#fff",
    color: "green",
  },
};
// Customizable Area End
