import React from "react";
import {

  // Customizable Area Start
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
// Customizable Area Start

import Phonepeintegration1StatusController, {
  Props
} from "./Phonepeintegration1StatusController";
import Loader from "../../../components/src/Loader";
import { configJSON } from "./Phonepeintegration1Controller";
// Customizable Area End

export default class Phonepeintegration1Status extends Phonepeintegration1StatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="md">
        <Paper style={webStyle.paper}>
          <TableContainer style={webStyle.tableContainer}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell style={webStyle.tableHeadings}>{configJSON.transactionIdText}</TableCell>
                  <TableCell style={webStyle.tableHeadings}>{configJSON.amountText}</TableCell>
                  <TableCell style={webStyle.tableHeadings}>{configJSON.statusText}</TableCell>
                  <TableCell style={webStyle.tableHeadings}>{configJSON.paymentTypeText}</TableCell>
                  <TableCell style={webStyle.tableHeadings}>{configJSON.messageText}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow data-test-id="orderList">
                  <TableCell scope="row">{this.state.statusResponse.data.merchantTransactionId || "NA"}</TableCell>
                  <TableCell>{`Rs  ${(this.state.statusResponse.data.amount / 100).toFixed(2)}`}</TableCell>
                  <TableCell>{this.state.statusResponse.data.state || "NA"}</TableCell>
                  <TableCell>{this.state.statusResponse.data.paymentInstrument.type || "NA"}</TableCell>
                  <TableCell>
                    {this.state.statusResponse.message || "NA"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div style={webStyle.goBackDiv}>
          <Button data-test-id="back-to-product" variant="contained" color="primary" style={{ textTransform: 'none' }}
            onClick={this.goBackToProductPage}
          >{configJSON.backToProduct}
          </Button>
        </div>
          <Loader loading={this.state.isLoaderVisible} data-test-id="loader" />
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cartHeading: { color: "rgb(98, 0, 238)" },
  closeModalContainer: {
    display: "flex",
    padding: "10px 0px",
    justifyContent: "flex-end",
  },
  tableHeadings: {
    color: "rgb(98, 0, 238)",
    textTransform: "capitalize" as "capitalize"
  },
  goBackDiv : {
    display:'flex',
    justifyContent:'flex-end'
  },
  modalAddButton: {
    justifyContent: "flex-end",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  modal: {

    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },


  paperContainer: {
    width: "100%",
    overflow: "hidden",
    marginBottom:"10px"
  },
  note: {
    width: "100%" as "100%",
    textAlign: "center" as "center",
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainer: {
    maxHeight: 440,
  },
};
// Customizable Area End
