import React from "react";

import {
  Container,
  Typography,
  Box,
  Checkbox,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import OrderDetailsController, { Props } from "./OrderDetailsController.web";
import ReturnOrderPopup from "./ReturnOrderPopup.web";

export default class OrderDetails extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            style={OrderDetailswebStyle.orderDetailsHead}>
            Order Details
          </Typography>
          <Box data-test-id="containerModal">
            {this.state.orderItems.map((item, index: number) => (
              <Box style={OrderDetailswebStyle.checkBoxWrapper} key={`item${index}`}>
                <Box style={{ margin: "10px" }}>
                  {this.state.status === "DELIVERED" && (
                    <Checkbox
                      data-test-id="handleChangeCheckBox"
                      value={this.state.selectedIds.includes(item.id)}
                      onChange={() => this.handleCheckboxChange(item.id)}
                    />
                  )}
                </Box>
                <Box>
                  <Typography style={OrderDetailswebStyle.orderItemsContent}>
                    Order Item {index + 1}
                  </Typography>
                  <Typography>Price : {item.attributes.price}</Typography>
                  <Typography>Quantity : {item.attributes.quantity}</Typography>
                  <Typography>
                    Name : {item.attributes.catalogue.data.attributes.name}
                  </Typography>
                  <Typography>
                    Category Name :
                    {
                      item.attributes.catalogue.data.attributes.category
                        .attributes.name
                    }
                  </Typography>
                  <Typography>
                    Sub Category Name :
                    {
                      item.attributes.catalogue.data.attributes.sub_category
                        .name
                    }
                  </Typography>
                </Box>
              </Box>
            ))}
            {this.state.status === "DELIVERED" &&
            this.state.selectedIds.length > 0 ? (
              <Button
                data-test-id="modalOpened"
                style={OrderDetailswebStyle.buttonWrapper}
                onClick={this.openModal}>
                Return Order
              </Button>
            ) : null}
            <ReturnOrderPopup
              data-test-id={"orderReturnModal"}
              isReturnItemModalOpen={this.state.isReturnItemModalOpen}
              closeModal={this.closeModal}
              validateReturnOrder={this.validateReturnOrder}
              updateInputValue={this.updateInputValue}
              returnOrderlength={this.state.returnOrderlength}
              returnOrderbreadth={this.state.returnOrderbreadth}
              returnOrderheight={this.state.returnOrderheight}
              returnOrderweight={this.state.returnOrderweight}
              isReturnLoading={this.state.isReturnLoading}
            />
          </Box>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
const OrderDetailswebStyle = {
  orderDetailsHead: {
    color: "blue",
    textDecoration: "underline",
    marginBottom: "15px",
    textAlign: "center",
  },

  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "auto",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "50%",
  },

  checkBoxWrapper: {
    border: "1px solid",
    width: "50%",
    margin: "auto",
    marginBottom: "15px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },

  orderItemsContent: {
    textDecoration: "underline",
    fontSize: "18px",
    marginBottom: "15px",
  },
} as Record<string, Record<string, string | number>>;
// Customizable Area End
