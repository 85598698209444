import React from "react";

import {
  Container,
  // Customizable Area Start
  Box,
  AppBar,
  Tab,
  Tabs,
  TextField,
  Button,
  Paper,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {MessageOutlined, ForumOutlined, ExitToApp,ChatBubble } from '@material-ui/icons';
import { CometChatUsersWithMessages, CometChatGroupsWithMessages, CometChatConversationsWithMessages } from '@cometchat/chat-uikit-react'
// Customizable Area End

import CometchatintegrationController, {
  Props,
} from "./CometchatintegrationController";

export default class Cometchatintegration extends CometchatintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="lg">
        {this.state.isLoggedIn ? (
          <Container maxWidth="md">
            <Box style={webStyle.logoutContainer}>
              <Button variant="outlined" color="secondary" onClick={this.handleLogout} data-test-id="logout">
                Logout
                <ExitToApp />
              </Button>
            </Box>
            <AppBar position="static">
              <Tabs value={this.state.tab} onChange={this.handleTabChange} data-test-id="tabs">
              <Tab icon={<ChatBubble />} label="Conversations" data-test-id="tabConversations" />
              <Tab icon={<MessageOutlined />} label="Users" data-test-id="tabUsers" />
              <Tab icon={<ForumOutlined />} label="Group" data-test-id="tabGroup" />
              </Tabs>
            </AppBar>
            {this.state.tab === 0 && <CometChatConversationsWithMessages data-test-id="cometChatConversations"/>}
            {this.state.tab === 1 && <CometChatUsersWithMessages data-test-id="usersWithMessages"/>}
            {this.state.tab === 2 && <CometChatGroupsWithMessages />}
          </Container>
        ) : (
          <Container maxWidth="sm">
            <Box style={webStyle.authCard}>
              <Paper style={webStyle.authCard}>
                <Typography>Login</Typography>
                <TextField
                  id="uid"
                  label="UID"
                  value={this.state.uid}
                  onChange={this.handleUIDChange}
                  margin="normal"
                  variant="outlined"
                  data-test-id="uid"
                />
                <Button onClick={this.handleLogin} variant="contained" fullWidth data-test-id="login">Login</Button>
              </Paper>
            </Box>
            <Box style={webStyle.authCard}>
              <Paper style={webStyle.authCard}>
                <Typography>Register</Typography>
                <Box>
                  <TextField
                    id="uidRegister"
                    label="UID"
                    value={this.state.uidRegister}
                    onChange={this.handleUIDRegisterChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    data-test-id="uidRegister"
                  />
                  <TextField
                    id="name"
                    label="Name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    data-test-id="name"
                  />
                </Box>
                <Button onClick={this.handleRegister} variant="contained" fullWidth data-test-id="register">Register</Button>
              </Paper>
            </Box>
          </Container>
        )}
        
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  logoutContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '2rem'
  },
  authCard: {
    padding: '1rem',
    margin: '2rem',
  }
}
// Customizable Area End
