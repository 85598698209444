import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomerDetailPopup from "./CustomerDetailPopup.web";
import ShipmentDetailsPopup from "./ShipmentDetailsPopup.web";
import { ICustomerDataTwo, OrderDataAttributesOrderItem } from "./types/types";

import {
  Container,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ShiprocketIntegrate2Controller, {
  Props,
} from "./ShiprocketIntegrate2Controller.web";

export default class ShiprocketIntegrate2 extends ShiprocketIntegrate2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container>
          <Typography variant="h4" style={shipWebStyle.shipMainWebTitle}>
            Orders Table
          </Typography>
          <TableContainer component={Paper}>
            <Table
              style={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Order number</TableCell>
                  <TableCell align="right">Customer Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right"> Phone Number</TableCell>
                  <TableCell align="right">Order Date</TableCell>
                  <TableCell align="right"> Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableData &&
                  this.state?.tableData?.data.map((item: ICustomerDataTwo, index) => {
                    return (
                      <TableRow
                        data-test-id="tableRowId"
                        key={`item${index}`}
                        onClick={() => this.handleCustomerData(item)}>
                        <TableCell component="th" scope="row">
                          {item.id}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}>
                          <Typography>
                            {
                              item.attributes.customer.data.attributes
                                .first_name
                            }
                          </Typography>
                          <Typography>
                            {item.attributes.customer.data.attributes.last_name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {item.attributes.customer.data.attributes.email}
                        </TableCell>
                        <TableCell align="right">
                          {
                            item.attributes.customer.data.attributes
                              .full_phone_number
                          }
                        </TableCell>
                        <TableCell align="right">
                          {item.attributes.customer.data.attributes.created_at}
                        </TableCell>
                        <TableCell align="right">
                          {item.attributes.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <CustomerDetailPopup
          data-test-id={"customerDataPop"}
          sendShipRocket={(orderId: string) => this.sendShipRocket(orderId)}
          open={this.state.setOpen}
          data={this.state.customerData}
          handleClose={this.handleClose}
          handleNavigate={this.handleNavigateToUpdate}
          handleOrderStatusDetails={this.handleOrderStatusDetails}
          shipmentDetalsFetch={(orderId: string) =>
            this.shipmentDetalsFetch(orderId)
          }
          isLoadingGetShipRocketDetails={
            this.state.isLoadingGetShipRocketDetails
          }
          isLoadingSendShipRocket={this.state.isLoadingSendShipRocket}
        />
        {this.state.shipRocketModalData !== null && (
          <ShipmentDetailsPopup
            data-test-id={"shipmentDetailsPop"}
            shipRocketModalData={this.state.shipRocketModalData}
            data={this.state.customerData}
            showShipmentDataModal={this.state.showShipmentDataModal}
            handleClose={this.handleShipmentClose}
            handleInvoice={(idInvoice: string) => this.handleInvoice(idInvoice)}
            generateAwb={(orderId: string) => this.generateAwb(orderId)}
            downloadLabel={(orderId: string) => this.downloadLabel(orderId)}
            awbError={this.state.awbError}
            labelDownloadError={this.state.labelDownloadError}
            pickUpRequestError={this.state.pickUpRequestError}
            pickupRequest={(orderId: string) => this.pickupRequest(orderId)}
            downloadManifest={(orderId: string) =>
              this.downloadManifest(orderId)
            }
            manifestError={this.state.manifestError}
            OrderCancel={(orderId: string) => this.OrderCancel(orderId)}
            viewOrderDetails={(
              orderData: OrderDataAttributesOrderItem[],
              status: string,
              orderId: string,
            ) => this.viewOrderDetails(orderData, status, orderId)}
            isLoadingCancelOrder={this.state.isLoadingCancelOrder}
            isLoadingInvoice={this.state.isLoadingInvoice}
            isLoadingAwb={this.state.isLoadingAwb}
          />
        )}
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
// Customizable Area Start
const shipWebStyle = {
  shipMainWebTitle: {
    color: "#6200EE",
    textAlign: "center" as "center",
    paddingTop: 20,
    paddingBottom: 20,
    fontWeight: "bold" as "bold",
    textTransform: "uppercase" as "uppercase",
  },
  textStyleWeb: {
    color: "#000",
    fontSize: "16px",
  },
  trackMainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  trackCardWeb: {
    padding: "10px",
    margin: "10px",
    border: "1px solid rgb(98, 0, 238)",
  },

  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
};
// Customizable Area End
