Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.postApiMethod = "POST";
exports.getApiMethod = "GET";
exports.putApiMethod = "PUT";
exports.postCreateShipmentAPiEndPoint = "bx_block_easypost/shipment?order_id=";
exports.postCreateShipmentApiContentType = "application/json";
exports.getShippingCartOrderAPiEndPoint = "bx_block_easypost/orders"
exports.postPlaceOrderAPiEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/save_rate?rate_id="

// Customizable Area End