import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ResponseMessageHandler } from "./helper/ResponseMessageHandler";
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  merchantId: string
  salt: string
  paymentMethod: string
  amount:string
  qrCode:string
  openModal:boolean
  isLoaderVisible:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Phonepeintegration1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQrCodeApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      merchantId: '',
      salt: '',
      paymentMethod: '',
      amount:"",
      qrCode:"",
      openModal:false,
      isLoaderVisible:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
        if(requestCallDataId === this.getQrCodeApiId){
        ResponseMessageHandler({
            jsonRes: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState({qrCode:responseSuccessWeb.data.instrumentResponse.qrData, isLoaderVisible:false})
            },
            onFail: () => {
              this.showAlert(
                `Error`,
                responseSuccessWeb.error.code 
              )
              this.setState({isLoaderVisible:false})
            }
          })
        }
    }
    // Customizable Area End
  }

  // web events
  // Customizable Area Start
  setMerchantValue = (text: string) => {
    this.setState({ merchantId: text });
  };
  onChangeMerchantId = (element:ChangeEvent<HTMLInputElement>)=>{
    this.setMerchantValue(element.target.value)
     this.setState({ qrCode: "" })
  } 
  onChangeAmount = (element:ChangeEvent<HTMLInputElement>) =>{
    this.setAmount(element.target.value)
    this.setState({ qrCode: "" })
  }
  setAmount= (text: string) => {
    this.setState({ amount: text });
  };
  resetAllFields = () => {
    this.setState({ merchantId: '', amount: '',qrCode:"" })
  }
  handleClickShowQr = () => {
    this.setState({isLoaderVisible:true})
    const body = {
      "merchantId": this.state.merchantId,
      "merchantTransactionId": "MT7850590068188104",
      "merchantUserId": "MUID123",
      "amount": +this.state.amount*100,
      "paymentInstrument": {
        "type": "UPI_QR"
      }
    }
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getQrCodeApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.payApiEndPoint}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
