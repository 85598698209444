import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ResponseMessageHandler } from "./helper/ResponseMessageHandler";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusResponse: {
    success: string,
    code: string,
    message: string,
    data: {
      merchantId: string;
      merchantTransactionId: string;
      transactionId: string;
      amount: number;
      state: string;
      responseCode: string;
      paymentInstrument: {
        type: string;
        pgTransactionId: string;
        pgServiceTransactionId: string;
        bankTransactionId: string;
        bankId: string;
      }
    }
  }
  isLoaderVisible:boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class Phonepeintegration1StatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiPhonePeTransactionStatusId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isLoaderVisible:false,
      statusResponse: {
        success: "",
        code: "",
        message: "",
        data: {
          merchantId: "",
          transactionId: "",
          amount: 0,
          merchantTransactionId: "",
          state: "",
          responseCode: "",
          paymentInstrument: {
            type: "",
            pgTransactionId: "",
            pgServiceTransactionId: "",
            bankTransactionId: "",
            bankId: "",
          }
        },
      },
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      if (requestCallDataId === this.getApiPhonePeTransactionStatusId) {
        ResponseMessageHandler({
          jsonRes: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.setState({
              isLoaderVisible:false,
              statusResponse: responseSuccessWeb
            })
          },
          onFail: () => {
            this.setState({isLoaderVisible:false})
              if (responseSuccessWeb.error.message) {
                this.showAlert(
                  `Error`,
                  responseSuccessWeb.error.message
                )
              } else if (responseSuccessWeb.error.code) {
                this.showAlert(
                  `Error`,
                  responseSuccessWeb.error.code
                )
              }else {
                this.showAlert(
                  `Error`,
                  configJSON.alertErrorMessage
                )
              }
            
          }
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.callStatusApi()
  }
  // Customizable Area End


  // Customizable Area Start
  callStatusApi = async() => {
    this.setState({isLoaderVisible:true})
    const transactionId = await getStorageData("transactionId");
    let merchantId = configJSON.merchantId

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getApiPhonePeTransactionStatusId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.statusApiEndPoint}${transactionId}&merchant_id=${merchantId}`,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  goBackToProductPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.productPageRoute,
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  // Customizable Area End
}
