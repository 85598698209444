import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider";
import { AnalyticsResponse } from "../../types/types";
import { 
  VerticalBarSeriesPoint,
} from 'react-vis';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accessToken: string;
  from: string;
  to: string;
  analyticsResponse: AnalyticsResponse | null;
  analyticsOrders: VerticalBarSeriesPoint[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EasypostAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAnalyticsApiCallId: string = "";
  getAnalyticsOrdersApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accessToken: "",
      from: new Date().toJSON().slice(0, 10),
      to: new Date().toJSON().slice(0, 10),
      analyticsResponse: null,
      analyticsOrders: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.getAnalyticsApiCallId:
        this.setState({ analyticsResponse: responseJson })
        break;
      case this.getAnalyticsOrdersApiCallId:
        if (responseJson.data) {
          this.setState({ analyticsOrders: responseJson.data })
        }
        break;
    }
    // Customizable Area End
  }

  handleFromInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({from: event.target.value as string})
  }

  handleToInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({to: event.target.value as string})
  }

  getAnalyticsRequest = () => {
    if (!(this.state.to && this.state.from)) {
      return
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAnalyticsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnalyticsAPiEndPoint + "start_date=" + this.state.from + "&end_date=" + this.state.to,
    );
    const header = {
      "Content-Type": configJSON.getOrdersApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAnalyticsOrdersRequest = () => {
    if (!(this.state.to && this.state.from)) {
      return
    }
    this.getAnalyticsRequest();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAnalyticsOrdersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnalyticsOrdersAPiEndPoint + "start_date=" + this.state.from + "&end_date=" + this.state.to,
    );
    const header = {
      "Content-Type": configJSON.getOrdersApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    const accessToken = await storage.get("token");

    this.setState({ accessToken }, () => {
      this.getAnalyticsRequest();
      this.getAnalyticsOrdersRequest();
    });
  }
  // Customizable Area End
}
