import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
// Customizable Area End
import { ChangeEvent } from "react";
import { getStorageData } from "framework/src/Utilities";
import { OrderDataAttributesOrderItem } from "./types/types";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  updateToken: string;
  returnOrderlength: string;
  returnOrderbreadth: string;
  returnOrderheight: string;
  returnOrderweight: string;
  status: string;
  selectedIds: string[];
  isReturnItemModalOpen: boolean;
  isReturnLoading: boolean;
  orderItems: OrderDataAttributesOrderItem[];
  orderId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addressApiCallId: string = "";
  updateApiCallId: string = "";
  apiOrderReturnById: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderId: "",
      updateToken: "",
      returnOrderlength: "",
      returnOrderbreadth: "",
      returnOrderheight: "",
      returnOrderweight: "",
      status: "",
      selectedIds: [],
      isReturnItemModalOpen: false,
      isReturnLoading: false,
      orderItems: [],

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const updateToken = await storage.get("token");
    const orderDetails = await storage.get("orderDetails");
    const deliveryStatus = await storage.get("status");
    const orderId = await storage.get("orderId");

    this.setState({
      updateToken,
      status: deliveryStatus,
      orderItems: JSON.parse(orderDetails),
      orderId: orderId,
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      if (apiRequestCallId === this.apiOrderReturnById) {
        this.handleResponse(message);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleResponse(message: Message) {
    this.setState({
      isReturnLoading: false,
      selectedIds: [],
      isReturnItemModalOpen: false,
    });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data && responseJson.data.message) {
      this.showAlert("", responseJson.data.message);
    } else {
      this.showAlert("", responseJson.data.status);
    }
  }

  handleCheckboxChange = (pickUpId: string) => {
    const { selectedIds } = this.state;
    const isSelected = selectedIds.includes(pickUpId);

    this.setState({
      selectedIds: isSelected
        ? selectedIds.filter((selectedId) => selectedId !== pickUpId)
        : [...selectedIds, pickUpId],
    });
  };

  openModal = () => {
    this.setState({
      isReturnItemModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isReturnItemModalOpen: false,
    });
  };

  isNonNullAndEmpty(value: string) {
    return value !== undefined && value !== null && value !== "";
  }

  updateInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let name = event.target.name;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  returnOrder = async () => {
    this.setState({
      isReturnLoading: true,
    });
    const {
      selectedIds,
      returnOrderlength,
      returnOrderbreadth,
      returnOrderheight,
      returnOrderweight,
    } = this.state;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiOrderReturnById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    const httpBody = {
      order_items_ids: selectedIds,
      length: returnOrderlength,
      weight: returnOrderweight,
      breadth: returnOrderbreadth,
      height: returnOrderheight,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postReturnOrderEndPoint}${this.state.orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateReturnOrder = () => {
    if (
      !this.isNonNullAndEmpty(this.state.returnOrderlength) ||
      !this.isNonNullAndEmpty(this.state.returnOrderbreadth) ||
      !this.isNonNullAndEmpty(this.state.returnOrderweight) ||
      !this.isNonNullAndEmpty(this.state.returnOrderheight) ||
      this.state.returnOrderlength === "0" ||
      this.state.returnOrderbreadth === "0" ||
      this.state.returnOrderheight === "0" ||
      this.state.returnOrderweight === "0"
    ) {
      this.setState({ isReturnItemModalOpen: false });
      this.showAlert("Alert", configJSON.fieldsEmptyWarningMsg);
    } else {
      this.returnOrder();
    }
  };

  // Customizable Area End
}
