Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Merchant ID";
exports.textSaltPlaceHolder = "Salt";
exports.labelTitleText = "phonepeintegration1";
exports.labelBodyText = "phonepeintegration1 Body";

exports.postApiMethod = "POST"
exports.quantityText = "Quantity"
exports.btnExampleTitle = "CLICK ME";
exports.payApi = "/pg/v1/pay";
exports.mobileNumber = 9869869869
exports.orderAmount = 2;
exports.merchantId = "PGTESTPAYUAT96";
exports.statusApiEndPoint = "bx_block_phonepeintegration1/phonepe_checkout/check_status_phonepe_payment?merchant_transaction_id="
exports.payApiEndPoint = "bx_block_phonepeintegration1/phonepe_checkout"
exports.phonePeRedirectEndpoint = "/Phonepeintegration1Status"
exports.displayQrRoutePath = "Phonepeintegration1"
exports.productPageRoute = "Phonepeintgration1Checkout"
exports.randomiseTransactionId = () => {
  let lastDigits = Date.now();
  let transactionId = `MT${lastDigits}`
  return transactionId;

}
exports.titleText = "title Text"

exports.merchantIdText = "Merchant Id"
exports.transactionIdText = "Merchant Transaction Id"
exports.amountText = "Amount"
exports.statusText = "Status"
exports.paymentTypeText = "Payment Type"
exports.messageText = "Message"
exports.backToProduct = "Go Back To Product Page"
exports.shopperSectionText = "Shopper Section"
exports.serialNoText = "Serial. No"
exports.orderAmountText = "Order Amount"
exports.quantityText = "Quantity"
exports.checkoutText = "checkout"
exports.merchantSctionText = "Merchant Section"
exports.displayQrText = "Display QR"
exports.NaText = "NA"
exports.enterToShowQrText = "Enter Data to show QR"
exports.resetAllText = "Reset All"
exports.showQrText = "Show QR"
exports.alertErrorMessage = "oops, something went wrong!"




// Customizable Area End