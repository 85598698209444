import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { ICustomerDataThree, ICustomerDataTwo, ReusableCustomerData } from "./types/types";
const configJSON = require("./config");

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: ".2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
  },
  shiprocketbtn: {
    backgroundColor: "#2297f8",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },
  popUpButton: {
    backgroundColor: "#2297f8",
    padding: "5px",
    borderRadius: "10px",
    color: "black",
    display: "block",
    width: "100%",
    margin: "8px",
  },

  disabledButton: {
    backgroundColor: "#808080",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  errorColor: {
    color: "red",
  },

  inputLable: {
    color: "rgb(98, 0, 238)",
    margin: "5px 0",
  },
  mainUpdateContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  dimensionHeading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  doneButton: {
    color: "green",
    marginTop: "5px",
  },
  cancelButton: {
    color: "red",
    marginTop: "5px",
  },
}));

export type Props = {
  data:
    | ICustomerDataTwo
    | ICustomerDataThree | ReusableCustomerData;
  open: boolean;
  handleClose: () => void;
  handleNavigate: () => void;
  sendShipRocket: (orderId: string) => void;
  shipmentDetalsFetch: (orderId: string) => void;
  handleOrderStatusDetails: () => void;
  isLoadingGetShipRocketDetails: boolean;
  isLoadingSendShipRocket: boolean;
};

const CustomerDetailPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.open}>
        <Box className={classes.paper}>
          <Typography
            id="transition-modal-title"
            className={classes.dimensionHeading}>
            {configJSON.textCustomerDetails}
          </Typography>
          <Typography id="transition-modal-description">
            {configJSON.textName} :{" "}
            {props.data.attributes.customer.data.attributes.first_name +
              " " +
              props.data.attributes.customer.data.attributes.last_name}
          </Typography>
          <Typography id="transition-modal-description">
            {configJSON.textEmail} :{" "}
            {props.data.attributes.customer.data.attributes.email}
          </Typography>
          <Typography id="transition-modal-description">
            {configJSON.textMobile} :{" "}
            {props.data.attributes.customer.data.attributes.full_phone_number}
          </Typography>

          <Typography
            id="transition-modal-title"
            className={classes.dimensionHeading}>
            {configJSON.textOrderDetails}
          </Typography>
          {props.data.attributes.order_items.data.length ? (
            <Box key={props.data.attributes.order_items.data[0].id}>
              <Typography id="transition-modal-description">
                {configJSON.textPrice} :{" "}
                {props.data.attributes.order_items.data[0].attributes.price}
              </Typography>
              <Typography id="transition-modal-description">
                {configJSON.textQuantity} :{" "}
                {props.data.attributes.order_items.data[0].attributes.quantity}
              </Typography>
              <Typography id="transition-modal-description">
                {configJSON.textName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.name
                }
              </Typography>
              <Typography id="transition-modal-description">
                {configJSON.textCategoryName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.category.attributes.name
                }
              </Typography>
              <Typography id="transition-modal-description">
                {configJSON.textSubCategoryName} :{" "}
                {
                  props.data.attributes.order_items.data[0].attributes.catalogue
                    .data.attributes.sub_category.name
                }
              </Typography>
            </Box>
          ) : (
            <Typography>{configJSON.textNoOrder}</Typography>
          )}
          <Box>
            {!props.data.attributes.ship_rocket_order_id && (
              <Button
                variant="contained"
                data-test-id="sendShipRocketButton"
                style={customerDetailsWebStyle.buttonWrapper}
                onClick={() =>
                  props.data && props.sendShipRocket(props.data.id)
                }>
                {props.isLoadingSendShipRocket ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                  configJSON.textSendToShipRocket
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={customerDetailsWebStyle.buttonWrapper}
              onClick={props.handleOrderStatusDetails}>
              {configJSON.textTrackOrder}
            </Button>
            {props.data.attributes.status === "completed" ||
            props.data.attributes.status === "cancelled" ? null : (
              <Button
                variant="contained"
                style={customerDetailsWebStyle.buttonWrapper}
                onClick={props.handleNavigate}>
                {configJSON.textUpdateOrder}
              </Button>
            )}

            {props.data.attributes.ship_rocket_order_id && (
              <Button
                variant="contained"
                data-test-id="updateOrderButton"
                style={customerDetailsWebStyle.buttonWrapper}
                onClick={() =>
                  props.data && props.shipmentDetalsFetch(props.data.id)
                }>
                {props.isLoadingGetShipRocketDetails ? (
                  <CircularProgress size="1.5rem" color="inherit" />
                ) : (
                  configJSON.textViewShipRocketDetails
                )}
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomerDetailPopup;

const customerDetailsWebStyle = {
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },
};
