import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import storage from "framework/src/StorageProvider";
import { handleResponseMessage } from "./helpers/handle-response-message";
import { IHistotyCheckout } from "./types/Types";
const baseURL = require("../../../framework/src/config.js");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IHistotyCheckout;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  merchantId: string;
  orderId: string;
  amount: string | number;
  currency: string;
  redirectUrl: string;
  cancelUrl: string;
  language: string;
  billingName: string;
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry: string;
  billingTel: string;
  billingEmail: string;
  deliveryName: string;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryState: string;
  deliveryZip: string;
  deliveryCountry: string;
  deliveryTel: string;
  merchantParam1: string;
  merchantParam2: string;
  merchantParam3: string;
  merchantParam4: string;
  merchantParam5: string;
  promoCode: string;
  customerIdentifier: string;
  integrationType: string;
  controller: string;
  action: string;
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ccavenueintegration2CheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postApiOrderDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      merchantId: configJSON.merchantId,
      orderId: configJSON.orderId,
      amount: 0,
      currency: configJSON.currency,
      redirectUrl: `${baseURL.baseURL}/${configJSON.checkOutDecryptDataEndPoint}`,
      cancelUrl: `${baseURL.baseURL}/${configJSON.checkOutDecryptDataEndPoint}`,
      language: configJSON.language,
      billingName: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      billingCountry: configJSON.country,
      billingTel: "",
      billingEmail: "",
      deliveryName: "",
      deliveryAddress: "",
      deliveryCity: "",
      deliveryState: "",
      deliveryZip: "",
      deliveryCountry: configJSON.country,
      deliveryTel: "",
      merchantParam1: configJSON.additionalParam,
      merchantParam2: configJSON.additionalParam,
      merchantParam3: configJSON.additionalParam,
      merchantParam4: configJSON.additionalParam,
      merchantParam5: configJSON.additionalParam,
      promoCode: "",
      customerIdentifier: "",
      integrationType: configJSON.integrationType,
      controller: configJSON.controller,
      action: configJSON.action,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      const value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        `From: ${this.state.txtSavedValue} To: ${value}`,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const token = await storage.get("token");
      this.setState({ token: token });
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (requestCallDataId === this.postApiOrderDetailsCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            window.location.replace(`${baseURL.baseURL}/bx_block_ccavenueintegration2/iframe?enc_resp=${responseSuccessWeb.enc_resp}&access_code=${responseSuccessWeb.access_code}&token=${this.state.token}`);
          },
          onFail: () => {
            this.showAlert(`Error`, "Checkout is failed !");
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      amount:
        this.props.history.location.state?.data.item.attributes.total_fees +
        this.props.history.location.state?.data.item.attributes.total_tax,
      billingName:
        this.props.history.location.state?.data.item.attributes.customer.data
          .attributes.first_name,
      token: this.props.history.location.state?.data.token,
    });
  }
  handleApiCall = () => {
    const body = {
      show_iframe: true,
      merchant_id: this.state.merchantId,
      order_id: this.state.orderId,
      amount: this.state.amount,
      currency: this.state.currency,
      redirect_url: this.state.redirectUrl,
      cancel_url: this.state.cancelUrl,
      language: this.state.language,
      billing_name: this.state.billingName,
      billing_address: this.state.billingAddress,
      billing_city: this.state.billingCity,
      billing_state: this.state.billingState,
      billing_zip: this.state.deliveryZip,
      billing_country: this.state.billingCountry,
      billing_tel: this.state.billingTel,
      billing_email: this.state.billingEmail,
      delivery_name: this.state.deliveryName,
      delivery_address: this.state.deliveryAddress,
      delivery_city: this.state.deliveryCity,
      delivery_state: this.state.deliveryState,
      delivery_zip: this.state.deliveryZip,
      delivery_country: this.state.deliveryCountry,
      delivery_tel: this.state.deliveryTel,
      merchant_param1: this.state.merchantParam1,
      merchant_param2: this.state.merchantParam2,
      merchant_param3: this.state.merchantParam3,
      merchant_param4: this.state.merchantParam4,
      merchant_param5: this.state.merchantParam5,
      promo_code: "",
      customer_identifier: this.state.customerIdentifier,
      integration_type: this.state.integrationType,
      controller: this.state.controller,
      action: this.state.action,
    };
    this.doCreateObject({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.createObjectApiEndPoint,
      body: body,
      token: this.state.token,
    });
  };
  doCreateObject = (data: {
    contentType: string;
    method: string;
    endPoint: string;
    body: {
      show_iframe: boolean;
      merchant_id: string;
      order_id: string;
      amount: string | number;
      currency: string;
      redirect_url: string;
      cancel_url: string;
      language: string;
      billing_name: string;
      billing_address: string;
      billing_city: string;
      billing_state: string;
      billing_country: string;
      billing_tel: string;
      billing_email: string;
      delivery_name: string;
      delivery_address: string;
      delivery_city: string;
      delivery_state: string;
      delivery_zip: string;
      delivery_country: string;
      delivery_tel: string;
      merchant_param1: string;
      merchant_param2: string;
      merchant_param3: string;
      merchant_param4: string;
      merchant_param5: string;
      promo_code: string;
      customer_identifier: string;
      integration_type: string;
      controller: string;
      action: string;
    };
    token: string;
  }) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postApiOrderDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handelInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let name = event.target.name;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  handleSubmitCheckout = () => {
    if (
      this.state.amount === "" ||  this.state.amount === 0 ||
      this.state.billingName === "" ||
      this.state.billingAddress === "" ||
      this.state.billingCity === "" ||
      this.state.billingState === "" ||
      this.state.billingZip === "" ||
      this.state.billingTel === "" ||
      this.state.billingEmail === "" ||
      this.state.deliveryName === "" ||
      this.state.deliveryAddress === "" ||
      this.state.deliveryZip === "" ||
      this.state.deliveryTel === ""
    ) {
      this.showAlert("Alert", "Please filled all input fileds!");
    } else {
      this.handleApiCall();
    }
  };

  // Customizable Area End
}
