import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader";

// Customizable Area End

import Phonepeintegration1Controller, {
  Props,
  configJSON,
} from "./Phonepeintegration1Controller";

export default class Phonepeintegration1 extends Phonepeintegration1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"sm"}>
        <Box style={webStyle.box}>
          <h3>
            {configJSON.merchantSctionText}
          </h3>
        </Box>
        <Paper style={webStyle.paper}>
          <div style={webStyle.pageHeading}>
            <h4 style={webStyle.noMargin}>{configJSON.enterToShowQrText}</h4>
          </div>
          <Box style={webStyle.inputBox}>
            <TextField
              label={configJSON.merchantIdText}
              data-test-id="merchant-id"
              placeholder={configJSON.merchantId}
              fullWidth={true}
              value={this.state.merchantId}
              onChange={this.onChangeMerchantId}
            />
            <TextField
              label={configJSON.amountText}
              data-test-id="amount"
              placeholder="123"
              fullWidth={true}
              value={this.state.amount}
              onChange={this.onChangeAmount}
            />
          </Box>
          {this.state.merchantId && this.state.amount && (
            <div data-test-id = "qr-div" style={webStyle.btnContainer}>
              <Button onClick={this.resetAllFields} data-test-id="reset">{configJSON.resetAllText}</Button>
              <Button variant="contained" color="primary" data-test-id="show-qr" onClick={this.handleClickShowQr}>{configJSON.showQrText}</Button>
            </div>
          )}
          <Box style={webStyle.qrCodeDiv}>
            {this.state.qrCode && <img src={"data:image/png;base64," + this.state.qrCode} style={webStyle.qrImg} />}
          </Box>
        </Paper>
          <Loader loading={this.state.isLoaderVisible} data-test-id="loader" />
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  paper: {
    overflow: "hidden",
    width: "100%",
    padding: '2rem',
    paddingTop: '0'
  },
  box: {
    display: 'flex', 
    justifyContent: 'center' 
  },
  pageHeading : {
    display: 'flex', 
    justifyContent: 'center' 
  },
  noMargin : {
    margin: 0 
  },
  inputBox : {
    margin: '10px' 
  },
  btnContainer : {
    display: 'flex', 
    justifyContent: 'space-between' 
  },
  qrCodeDiv : {
    display: 'flex', 
    justifyContent: "center" 
  },
  qrImg : {
    width: 200, 
    height: 200 
  }
}
// Customizable Area End
