Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.environment = "test";
exports.defaultCountryValue = "GB";
exports.defaultCurrencyValue = "GBP";

exports.shopperLocale = "en-US";
exports.returnUrl = "https://localhost:3000/AdyenCheckout";
exports.reference = "1"
exports.shopperReference = "12345";
exports.shopperReferenceTwo = "Checkout Shopper";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.getOrdersApiEndPoint = "bx_block_shopping_cart/orders";
exports.createSessionEndPoint = "bx_block_adyen/checkout";
exports.getPaymentMethodsEndPoint = "bx_block_adyen/checkout/payment_methods";
exports.submitPaymentEndPoint = "bx_block_adyen/checkout/payment";
exports.getPaymentDetails = "bx_block_adyen/checkout/payment_details";
exports.getWebhookApi = "bx_block_adyen/checkout/webhook";
exports.getSupportedCurrenciesEndPoint = "bx_block_adyen/checkout/supported_processing_currency";
exports.getConvertedValueEndPoint = "bx_block_adyen/checkout/currency_conversion"
exports.getKeysEndPoint = "bx_block_adyen/checkout/adyen_keys"

exports.btnExampleTitle = "CLICK ME";
exports.headingText = "Adyen Integration";
exports.paymentButtonText = "Make Payment";

exports.errorTitle = "Error";
exports.alertMsg = "something went wrong";
exports.alertMessageText = "Oops! something went wrong";
exports.adyenKeysErrorMsg = "Oops! could not fetch adyen keys"
exports.paymentMethodsErrorMsg = "got error on payment methods";
exports.noOrdersToDisplay = "No orders to display !";
exports.noAvailableCurrenciesText = "No available currencies";
exports.convertApiErr = "Can not convert the value";
exports.paymentNotDoneText = 'payment is not completed';

exports.catalogueIdText = "Catalogue Id";
exports.orderIdText = "Order Id";
exports.currencyText = "Currency";
exports.catalogueText = "Catalogue";
exports.quantityText = "Quantity";
exports.taxableText = "Taxable";
exports.taxableValueText = "Taxable Value";
exports.idText = "Id";
exports.priceText = "Price";
exports.actionText = "Action";
exports.totalFeesText = "Total Fees";
exports.totalItemsText = "Total Items";
exports.totalTaxText = "Total Tax";
exports.customerText = "Customer";
exports.amountText = "Amount";
exports.checkOutButtonText = "Checkout";
exports.viewButtonText = "View";
exports.addButtonText = "Add";
exports.closeButtonText = "Close";
exports.cancelButtonText = "Cancel";
exports.deleteButtonText = "Delete";
exports.textCartPage = "Cart Page";
exports.checkoutText = "Checkout Page";
exports.checkOutButtonTextTwo = "Proceed to Checkout";
exports.orderSummaryHeading = "Order Summary";
exports.itemNameText = "Item Name";
exports.itemNameValue = "Watch";
exports.itemPriceText = "Item Price";
exports.countryText = "Country";
exports.successNote = "Payment is completed ,Thank you!";

exports.email = "youremail@email.com";
exports.city = "Ankeborg";
exports.telephoneNumber1 = "+4408082580300";
exports.telephoneNumber = "+4901761428434";
exports.firstName = "Testperson-se";
exports.lastName = "Approved";
exports.gender = "MALE";
exports.postalCode = "1234";
exports.street = "Stargatan";
exports.stateText = "New York";
exports.supportedCurrenciesText = "Supported Currencies";
exports.paymentDeclinedText = "Payment is declined";
exports.adyenCheckoutText = "AdyenCheckout";
exports.houseNumberOrNameText = "1";

// Customizable Area End
