import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ResponseMessageHandler } from "./helper/ResponseMessageHandler";
import { setStorageData } from "framework/src/Utilities";

// Customizable Area End
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  merchantId: string,
  payPageUrl:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class Phonepeintegration1CheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiPhonePePaypageId: string = "";
  getApiPhonePeTransactionStatusId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      merchantId: "",
      payPageUrl:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (requestCallDataId === this.getApiPhonePePaypageId) {
        ResponseMessageHandler({
          jsonRes: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            const link = document.createElement('a');
            link.href = responseSuccessWeb.data.instrumentResponse.redirectInfo.url;
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link);
          },
          onFail: () => {
            if (responseSuccessWeb.error.message) {
              this.showAlert(
                `Error`,
                responseSuccessWeb.error.message
              )
            } else if (responseSuccessWeb.error.code) {
              this.showAlert(
                `Error`,
                responseSuccessWeb.error.code
              )
            } else {
              this.showAlert(
                `Error`,
                configJSON.alertErrorMessage
              )
            }
          }
        })
      }
    }
    // Customizable Area End
  }



  // Customizable Area Start
  handleCheckout = () => {

    let transactionId = configJSON.randomiseTransactionId()
    const baseUrl = document.location ? document.location.origin : null
    let merchantId = configJSON.merchantId
    setStorageData("transactionId", `${transactionId}`)
    const body = {
      "merchantId": merchantId,
      "merchantTransactionId": `${transactionId}`,
      "merchantUserId": "MUID123",
      "amount": configJSON.orderAmount * 100,
      "redirectUrl": `${baseUrl}${configJSON.phonePeRedirectEndpoint}`,
      "mobileNumber": "9999999999",
      "paymentInstrument": {
        "type": "PAY_PAGE"
      }
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getApiPhonePePaypageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST",
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.payApiEndPoint}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  openQrPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.displayQrRoutePath,
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  

  // Customizable Area End
}
