Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cometchatintegration";
exports.labelBodyText = "cometchatintegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.appID = "248799e094a5c938";
exports.region = "us";
exports.authKey = "e6044212e7b578bba93f50516cfe4fa0e7d9adc3";
// Customizable Area End