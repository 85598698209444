import React, { FunctionComponent } from "react";
import {
  Modal,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "./CustomerDetailPopup.web";
import { ICustomerDataTwo, OrderDataAttributesOrderItem } from "./types/types";
const configJSON = require("./config");

export type Props = {
  shipRocketModalData?: {
    pickup_location?: string;
    net_total?: string;
    shipments?: {
      courier?: string;
      id?: string;
      awb?: string;
    };
    status?: string;
    awb_data: {
      awb: string;
    };
  } | null;
  data:
    | ICustomerDataTwo
    | {
        id: string;
        type?: string;
        attributes: {
          order_items: {
            data: [];
          };
          ship_rocket_order_id: string;
          ship_rocket_awb_code: string;
          status: string;
          customer: {
            data: {
              attributes: {
                name: string;
                first_name: string;
                last_name: string;
                email: string;
                full_phone_number: string;
                ship_rocket_order_id: string;
                status: string;
                sub_category: {
                  name: string;
                };
                category: {
                  attributes: {
                    name: string;
                  };
                };
              };
            };
          };
        };
      };
  showShipmentDataModal: boolean;
  handleClose: () => void;
  handleInvoice: (id: string) => void;
  generateAwb: (orderId: string) => void;
  downloadLabel: (orderId: string) => void;
  awbError: string;
  labelDownloadError: string;
  pickUpRequestError: string;
  pickupRequest: (orderId: string) => void;
  downloadManifest: (orderId: string) => void;
  manifestError: string;
  OrderCancel: (orderId: string) => void;
  viewOrderDetails: (
    orderData: OrderDataAttributesOrderItem[],
    status: string,
    orderId: string,
  ) => void;
  isLoadingCancelOrder: boolean;
  isLoadingInvoice: boolean;
  isLoadingAwb: boolean;
};

const ShipmentDetailsPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.showShipmentDataModal}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ width: "35%", margin: "auto" }}>
      <Fade in={props.showShipmentDataModal}>
        <Box className={classes.paper}>
          <Typography variant="h5">Shipment Details</Typography>
          <Box>
            <Typography>
              Pickup Location: {props.shipRocketModalData?.pickup_location}
            </Typography>
            <Typography>
              Total Amount: {props.shipRocketModalData?.net_total}
            </Typography>
            <Typography>Status: {props.shipRocketModalData?.status}</Typography>
            <Typography>
              Courier: {props.shipRocketModalData?.shipments?.courier}
            </Typography>
            <Typography>
              Shipment id: {props.shipRocketModalData?.shipments?.id}
            </Typography>
            <Typography>
              AWB code: {props.shipRocketModalData?.shipments?.awb}
            </Typography>
          </Box>
          <Button
            data-test-id="orderViewDetails"
            style={shipMentWebStyle.buttonWrapper}
            onClick={() =>
              props.viewOrderDetails(
                props.data.attributes.order_items.data,
                props.data.attributes.status,
                props.data.id,
              )
            }>
            {configJSON.labelViewOrderDetails}
          </Button>
          <Button
            disabled={props.data.attributes.ship_rocket_order_id ? false : true}
            style={
              props.data.attributes.ship_rocket_order_id
                ? shipMentWebStyle.buttonWrapper
                : shipMentWebStyle.disableButtonWrapper
            }
            onClick={() => props.handleInvoice(props.data.id)}
            data-test-id="handleInvoiceButton">
            {props.isLoadingInvoice ? (
              <CircularProgress size="1.5rem" color="inherit" />
            ) : (
              configJSON.downloadInvoiceText
            )}
          </Button>
          {!props.shipRocketModalData?.awb_data.awb && (
            <Button
              data-test-id="generateAwbButton"
              style={shipMentWebStyle.buttonWrapper}
              onClick={() => props.generateAwb(props.data.id)}>
              {props.isLoadingAwb ? (
                <CircularProgress size="1.5rem" color="inherit" />
              ) : (
                "Generate AWB"
              )}
            </Button>
          )}
          <Typography style={shipMentWebStyle.errorText}>
            {props.awbError}
          </Typography>
          {props?.data?.attributes?.ship_rocket_order_id &&
            props?.shipRocketModalData?.awb_data.awb && (
              <Button
                data-test-id="downloadLabel"
                style={shipMentWebStyle.buttonWrapper}
                onClick={() => props.downloadLabel(props?.data?.id)}>
                Download Label
              </Button>
            )}
          <Typography style={shipMentWebStyle.errorText}>
            {props.labelDownloadError}
          </Typography>
          {props?.data?.attributes?.ship_rocket_order_id &&
            props.shipRocketModalData?.awb_data.awb && (
              <>
                <Button
                  data-test-id="pickupRequestButton"
                  style={shipMentWebStyle.buttonWrapper}
                  onClick={() => props.pickupRequest(props.data.id)}>
                  Request Pickup
                </Button>
                <Typography style={shipMentWebStyle.errorText}>
                  {props?.pickUpRequestError}
                </Typography>
              </>
            )}
          {props.data.attributes?.ship_rocket_order_id &&
            props.shipRocketModalData?.awb_data.awb && (
              <>
                <Button
                  data-test-id="downloadManifestButton"
                  onClick={() => props.downloadManifest(props?.data?.id)}
                  style={shipMentWebStyle.buttonWrapper}>
                  {configJSON.labelDownloadManifest}
                </Button>
                <Typography style={shipMentWebStyle.errorText}>
                  {props.manifestError}
                </Typography>
              </>
            )}
          {props.data.attributes.status.toUpperCase() !== "DELIVERED" ? (
            <Button
              style={shipMentWebStyle.buttonWrapper}
              onClick={() => props.OrderCancel(props?.data?.id)}>
              {props.isLoadingCancelOrder ? (
                <CircularProgress size="1.5rem" color="inherit" />
              ) : (
                "Cancel Order"
              )}
            </Button>
          ) : null}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ShipmentDetailsPopup;

const shipMentWebStyle = {
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  disableButtonWrapper: {
    backgroundColor: "#808080",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },

  errorText: {
    color: "red",
  },

  titleText: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
    marginBottom: "10px",
  },
};
