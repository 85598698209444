Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getApiMethod = "GET";
exports.getShippingCartOrderAPiEndPoint = "bx_block_shiprocket/shopping_cart_orders";
exports.trackOrderEndPoint = "bx_block_shiprocket/track_order_by_order_id/";
exports.gettableDataApiContentType = "application/json";
exports.getCustomerAPiMethod = "GET";
exports.getCustomerDataAPiEndPoint = "bx_block_shopping_cart/orders";
exports.getCustomerDataApiContentType = "application/json";
exports.sendShipRocketEndPoint = "bx_block_shiprocket/create_order/";


exports.exampleAPiMethod = "POST"
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "shiprocketintegrate2";
exports.labelBodyText = "shiprocketintegrate2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.validationApiContentType = "application/json";

exports.address = "Address";
exports.address2 = "Address 2";
exports.city = "City";
exports.textDate = "Date";
exports.textLocation = "Location";
exports.state = "State";
exports.country = "Country";
exports.pinCode = "Pin Code";
exports.addressType = "Select Address Type";
exports.done = "Done";
exports.textHome = "Home";
exports.textWork = "Work";
exports.textOther = "Other";
exports.textLength = "Length";
exports.textBreadth = "Breadth";
exports.textHeight = "Height";
exports.textWeight = "Weight";
exports.textName = "Name";
exports.textEmail = "Email";
exports.textMobile = "Mobile";
exports.textPrice = "Price";
exports.textQuantity = "Quantity";
exports.textCategoryName = "Category Name";
exports.textSubCategoryName = "Sub Category Name";
exports.textSendToShipRocket = "Send to ShipRocket";
exports.textTrackOrder = "Track Order";
exports.textUpdateOrder = "Update Order Address";
exports.textViewShipRocketDetails = "View ShipRocket Details";
exports.textOrderStatusNotFound = "Order status not found";
exports.textGoBackToOrders = "Go back to orders"
exports.labelViewOrderDetails = "View Order Details";
exports.addressPlaceholder = "Enter address more than 3 characters";
exports.pincodePlaceholder = "Enter the pincode of 6 characters";



exports.getLabelEndPoint = "bx_block_shiprocket/generate_label/";
exports.getRequestPickupEndPoint = "bx_block_shiprocket/request_pickup/";
exports.addressCreateEndPoint = "bx_block_address/addresses";
exports.updateOrderEndPoint = "bx_block_shiprocket/shopping_cart_orders/";
exports.updateOrderEndPointWeb = "bx_block_shiprocket/shopping_cart_orders/";
exports.sendShipRocketEndPoint = "bx_block_shiprocket/create_order/";
exports.trackOrderEndPoint = "bx_block_shiprocket/track_order_by_order_id/";
exports.getShipRocketEndPoint = "bx_block_shiprocket/get_order/";
exports.cancelOrderEndPoint = "bx_block_shiprocket/cancel_order/";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "shiprocketintegrate2";
exports.labelBodyText = "shiprocketintegrate2 Body";
exports.getOrdersApiEndPoint = "bx_block_shopping_cart/orders";
exports.btnExampleTitle = "CLICK ME";
exports.buttonCancel = "Cancel";
exports.getDownloadInvoiceAPiMethod = "GET";
exports.getDownloadInvoiceAPiEndPoint = "bx_block_shiprocket/generate_invoice";
exports.getDownloadInvoiceApiContentType = "application/json";

exports.updateOrderTitle = "Update The Details";
exports.textCustomerDetails = "Customer Details";
exports.textOrderDetails = "Order Details";
exports.trackOrderTitle = "Tracking Status";
exports.updateOrderDimenensionText  = "Update order dimension";
exports.textNoOrder = "No order is present !";
exports.downloadInvoiceText = "Download Invoice";

exports.creatOrderErrorMsg = "Create new order details is failed !";
exports.updateOrderErrorMsg = "Update order details is failed !";
exports.commonErrorMsg = "Something went wrong!";
exports.fieldsEmptyWarningMsg = "All fields are mandatory!"
exports.getAwbEndPoint = "bx_block_shiprocket/generate_awb/";
exports.labelDownloadManifest = "Download Manifest";
exports.getManifestEndPoint = "bx_block_shiprocket/generate_manifest/";
exports.postReturnOrderEndPoint = "bx_block_shiprocket/return_order/";

// Customizable Area End

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End