import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider";
import { Rate } from "./types";
import { MyOrder, ORDER_STATUS } from "../../types/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  orderPlaced: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accessToken: string;
  rates: Rate[];
  selectedShipmentRate: Rate | null;
  isLoadingBuyShipment: boolean;
  orders: MyOrder[];
  selectedOrder: MyOrder | null;
  isTableLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShippingSpeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ordersGetApiCallId: string = "";
  shipmentPostApiCallId: string = "";
  postBuyShipmentApiCallId: string = "";
  postPlaceOrderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      accessToken: "",
      selectedShipmentRate: null,
      isLoadingBuyShipment: false,
      rates: [],
      orders: [],
      selectedOrder: null,
      isTableLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.ordersGetApiCallId:
        this.setState({ orders: responseJson.data.filter((item: MyOrder) => item.attributes.easypost_order_status === ORDER_STATUS.SCHEDULED ) });
        this.resetTableLoading();
        break;
      case this.postPlaceOrderApiCallId:
        this.props.orderPlaced();
        this.handleOrdersData();
        this.setState({selectedOrder: null, selectedShipmentRate: null, rates: []});
        break;
      case this.shipmentPostApiCallId:
        if (responseJson.rates) {
          this.setState({ rates: responseJson.rates.filter((rate: Rate) => !!rate.est_delivery_days) })
        }
        break;
    }
    // Customizable Area End
  }
  
  // Customizable Area Start

  handlePlaceOrder = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postPlaceOrderApiCallId = requestMessage.messageId;

    if (this.state.selectedOrder && this.state.selectedShipmentRate) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postPlaceOrderAPiEndPoint.replace('{ORDER_ID}', this.state.selectedOrder.id) + this.state.selectedShipmentRate.id,
      );
    }
    const header = {
      "Content-Type": configJSON.postCreateShipmentApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSelectedOrderChange = (order: MyOrder) => {
    this.setState({ selectedOrder: order});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.shipmentPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreateShipmentAPiEndPoint + order.id,
    );
    const header = {
      "Content-Type": configJSON.postCreateShipmentApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOrderSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (this.state.rates) {
      this.setState({ selectedShipmentRate: this.state.rates.find(rate => rate.id === event.target.value as string) || null })
    }
  };

  resetTableLoading () {
    this.setState({isTableLoading: false})
  }

  handleOrdersData = () => {
    if (this.state.isTableLoading) {
      this.resetTableLoading()
      return
    }
    this.setState({isTableLoading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.ordersGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShippingCartOrderAPiEndPoint,
    );
    const header = {
      "Content-Type": configJSON.getOrdersApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    const accessToken = await storage.get("token");

    this.setState({ accessToken }, () => {
      this.handleOrdersData();
    });
  }
  // Customizable Area End
}
