import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import {
  ICustomerDataTwo,
  IShipHistory,
  OrderDataAttributesOrderItem,
  ReusableCustomerData,
} from "./types/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IShipHistory;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tableData: {
    data: ICustomerDataTwo[];
  };
  customerData:
    | ICustomerDataTwo
    | ReusableCustomerData
  setOpen: boolean;
  detailsOpen: boolean;
  isLoadingSendShipRocket: boolean;
  shipToken: string;
  isLoadingGetShipRocketDetails: boolean;
  shipRocketModalData: {
    pickup_location?: string;
    net_total?: string;
    shipments?: {
      courier?: string;
      id?: string;
      awb?: string;
    };
    status?: string;
    awb_data: {
      awb: string;
    };
  } | null;
  data: {
    id?: string;
    attributes?: {
      ship_rocket_order_id?: string | boolean;
      ship_rocket_awb_code?: string;
    };
  } | null;
  showShipmentDataModal: boolean;
  isLoadingInvoice: boolean;
  isLoadingAwb: boolean;
  isLoadingLabel: boolean;
  awbError: string;
  labelDownloadError: string;
  isLoadingPickup: boolean;
  pickUpRequestError: string;
  isLoadingManifest: boolean;
  manifestError: string;
  isLoadingCancelOrder: boolean;
  orderId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShiprocketIntegrate2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tableDataGetApiCallId: string = "";
  customerDataApiCallId: string = "";
  postShipRocketApiCallId: string = "";
  shipRocketDetailsApiCallId: string = "";
  getInvoiceApiCallId: string = "";
  getAwbApiCallId: string = "";
  getLabelApiCallId: string = "";
  getRequestPickupApiCallId: string = "";
  getManifestApiCallId: string = "";
  postCancelOrderApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tableData: { data: [] },
      customerData: {
        id: "",
        type: "",
        attributes: {
          order_items: {
            data: [],
          },
          ship_rocket_order_id: "",
          ship_rocket_awb_code: "",
          status: "",
          customer: {
            data: {
              attributes: {
                name: "",
                first_name: "",
                last_name: "",
                email: "",
                full_phone_number: "",
                ship_rocket_order_id: "",
                status: "",
                sub_category: {
                  name: "",
                },
                category: {
                  attributes: {
                    name: "",
                  },
                },
              },
            },
          },
        },
      },
      setOpen: false,
      detailsOpen: false,
      isLoadingSendShipRocket: false,
      shipToken: "",
      isLoadingGetShipRocketDetails: false,
      shipRocketModalData: null,
      showShipmentDataModal: false,
      isLoadingInvoice: false,
      isLoadingAwb: false,
      isLoadingLabel: false,
      awbError: "",
      labelDownloadError: "",
      isLoadingPickup: false,
      pickUpRequestError: "",
      isLoadingManifest: false,
      manifestError: "",
      data: null,
      isLoadingCancelOrder: false,
      orderId: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.tableDataGetApiCallId:
        this.setState({ tableData: responseJson });
        break;

      case this.customerDataApiCallId:
        this.setState({ customerData: responseJson, setOpen: true });
        break;

      case this.postShipRocketApiCallId:
        this.handleShipRocketPostData(message);
        break;

      case this.shipRocketDetailsApiCallId:
        this.handleShipRocketDetailsData(message);
        break;

      case this.getInvoiceApiCallId:
        this.handleDownloadInvoiceResponse(message);
        break;

      case this.getAwbApiCallId:
        this.handleAwbResponse(message);
        break;

      case this.getLabelApiCallId:
        this.handleLebelResponse(message);
        break;

      case this.getRequestPickupApiCallId:
        this.handleRequestPickupResponse(message);
        break;

      case this.getManifestApiCallId:
        this.handleManifestResponse(message);
        break;

      case this.postCancelOrderApiCallId:
        this.handleCancelOrderResponse(message);
        break;
    }
    // Customizable Area End
  }

  // web events
  // Customizable Area Start

  handleShipRocketPostData = (message: Message) => {
    this.setState({
      isLoadingSendShipRocket: false,
      isLoadingGetShipRocketDetails: false,
      setOpen: false,
    });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data.status_code === 422 && responseJson.data.errors) {
      let alertMessage = "";
      if (responseJson.data.message) {
        alertMessage += `${responseJson.data.message}\n`;
      }
      if (responseJson.data.errors) {
        Object.keys(responseJson.data.errors).forEach(
          (param) =>
            (alertMessage += `${responseJson.data.errors[param][0]}\n`),
        );
      }
      this.showAlert("", alertMessage);
    } else {
      responseJson.data && this.handleTableData();
    }
  };

  handleLebelResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    if (responseJson.data && !responseJson.data.label_url) {
      this.setState({ labelDownloadError: responseJson.data.response });
    } else {
      let link = document.createElement("a");
      link.href = responseJson.data.label_url;
      link.setAttribute("download", "label.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  handleDownloadInvoiceResponse = (message: Message) => {
    this.setState({ isLoadingInvoice: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    let link = document.createElement("a");
    link.href = responseJson.data.invoice_url;
    link.setAttribute("download", "invoice.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  };

  handleRequestPickupResponse = (message: Message) => {
    this.setState({ isLoadingPickup: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (!responseJson.response && responseJson?.message) {
      this.setState({ pickUpRequestError: responseJson.message });
    } else if (responseJson.pickup_status === 1) {
      this.setState({ pickUpRequestError: responseJson.response.data });
    } else {
      this.setState({
        pickUpRequestError: "Something went wrong, Please try again later.",
      });
    }
  };

  handleAwbResponse = (message: Message) => {
    this.setState({ isLoadingAwb: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.message) {
      this.setState({ awbError: responseJson.message });
    } else if (
      responseJson.response.data &&
      !responseJson.response.data.awb_code
    ) {
      this.setState({ awbError: responseJson.response.data.awb_assign_error });
    } else {
      const { awb_code } = responseJson.response.data;
      if (awb_code) {
        this.shipmentDetalsFetch(this.state.orderId);
      }
    }
  };

  handleManifestResponse = (message: Message) => {
    this.setState({ isLoadingManifest: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data && !responseJson.data.manifest_url) {
      this.setState({ manifestError: responseJson.data.message });
    } else {
      let link = document.createElement("a");
      link.href = responseJson.data.manifest_url;
      link.setAttribute("download", "menifest.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode && link.parentNode.removeChild(link);
    }
  };

  downloadLabel = async (orderId: string) => {
    this.setState({ isLoadingLabel: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getLabelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLabelEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  pickupRequest = async (orderId: string) => {
    this.setState({ isLoadingPickup: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getRequestPickupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequestPickupEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleShipRocketDetailsData = (message: Message) => {
    this.setState({ isLoadingGetShipRocketDetails: false });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (
      responseJson.status_code === 404 ||
      responseJson.message ||
      responseJson.error
    ) {
      this.setState({ shipRocketModalData: null });
      this.showAlert("", "Shipment Details not found.");
    } else {
      this.setState(
        {
          shipRocketModalData: responseJson.data,
          setOpen: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ showShipmentDataModal: true });
          }, 50);
        },
      );
    }
  };

  handleClose = () => {
    this.setState({ setOpen: false });
  };

  handleShipmentClose = () => {
    this.setState({
      showShipmentDataModal: false,
      awbError: "",
      labelDownloadError: "",
      manifestError: "",
      pickUpRequestError: "",
    });
  };

  handleTableData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.tableDataGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShippingCartOrderAPiEndPoint,
    );
    const header = {
      "Content-Type": configJSON.gettableDataApiContentType,
      "token": this.state.shipToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCustomerData = (data: ICustomerDataTwo) => {
    this.setState({ customerData: data, setOpen: true });
  };

  handleCancelOrderResponse = (message: Message) => {
    this.setState({
      isLoadingCancelOrder: false,
      showShipmentDataModal: false,
      awbError: "",
      labelDownloadError: "",
      manifestError: "",
      pickUpRequestError: "",
    });
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    if (responseJson.data && responseJson.data.message) {
      this.showAlert(
        responseJson.data.status_code === 200 ? "Success" : "Error",
        responseJson.data.message,
      );
    }
  };

  downloadManifest = async (orderId: string) => {
    this.setState({ isLoadingManifest: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getManifestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getManifestEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  generateAwb = async (orderId: string) => {
    this.setState({ isLoadingAwb: true, orderId: orderId });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAwbApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAwbEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  shipmentDetalsFetch = async (orderId: string) => {
    this.setState({ isLoadingGetShipRocketDetails: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.shipRocketDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShipRocketEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInvoice = async (idInvoice: string) => {
    this.setState({ isLoadingInvoice: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getInvoiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDownloadInvoiceAPiEndPoint + "/" + idInvoice,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDownloadInvoiceAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendShipRocket = async (orderId: string) => {
    this.setState({ isLoadingSendShipRocket: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postShipRocketApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendShipRocketEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewOrderDetails = (
    orderData: OrderDataAttributesOrderItem[],
    status: string,
    orderId: string,
  ) => {
    this.setState({
      setOpen: false,
      showShipmentDataModal: false,
    });
    this.props.navigation.navigate("OrderDetails");
    storage.set("orderDetails", JSON.stringify(orderData));
    storage.set("status", status);
    storage.set("orderId", orderId);
  };

  OrderCancel = async (orderId: string) => {
    this.setState({ isLoadingCancelOrder: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.state.shipToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postCancelOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelOrderEndPoint}${orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateToUpdate = () => {
    this.state.customerData &&
      this.props.history.push("UpdateAddress", {
        data: {
          customerData: this.state.customerData,
        },
      });
  };
  handleOrderStatusDetails = () => {
    this.props.history.push("TrackOrder", {
      data: {
        customerData: this.state.customerData,
      },
    });
  };
  async componentDidMount() {
    const shipToken = await storage.get("token");
    this.setState({ shipToken }, () => this.handleTableData());
  }
  // Customizable Area End
}
// Customizable Area End
