import React from "react";
// Customizable Area Start
import { Button, Input } from '@builder/component-library';
import { Box } from "@material-ui/core"
// Customizable Area End

import Kaleyra2Controller, { Props, configJSON } from "./Kaleyra2Controller";

export default class Kaleyra2 extends Kaleyra2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.mainContainer}>
        <Box style={webStyle.mainWrapper}>
          <Box style={webStyle.mainBox} >
            <Box style={webStyle.apiKeyStyle} >
              <Input
                data-testId="apiKeyTestID"
                placeholder={"API KEY"}
                value={this.state.apiKeyValue}
                onChangeText={(value: string) => this.setApiKey(value)}
              />
            </Box>
            <Box style={webStyle.sidStyle}>
              <Input
                data-testId="sidTestID"
                placeholder={"SID"}
                value={this.state.sidValue}
                onChangeText={(value) => this.setSid(value)}
              />
            </Box>
            <Box style={webStyle.apiKeyStyle} >
              <Input
                data-testId="messageTestID"
                placeholder={"MESSAGE"}
                value={this.state.messageValue}
                onChangeText={(value) => this.setMessage(value)}
              />
            </Box>
            <Box style={webStyle.sidStyle}>
              <Input
                data-testId="phonenumberTestID"
                placeholder={"PHONE NUMBER"}
                value={this.state.phoneNumberValue}
                onChangeText={(value) => this.setPhoneNumber(value)}
              />
            </Box>
            <Box style={webStyle.sidStyle}>
              <Input
                data-testId="senderTestID"
                placeholder={"SENDER ID"}
                value={this.state.senderIdValue}
                onChangeText={(value) => this.setSenderId(value)}
              />
            </Box>
            <Button data-testId="submitTestID" text={configJSON.kayleraSubmit} onPress={() => this.submitMessage()} />
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    paddingBottom: "30px",
    width: "60%"
  },
  btnStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    cursor: "pointer",
    display: "grid",
    alignItem: "center",
    justifyContent: "center"
  },
  apiKeyStyle: {
    display: "grid",
    height: "30px",
    padding: "20px 0px"
  },

  sidStyle: {
    padding: "20px 0px",
    display: "grid",
    height: "30px"
  },
  btnSubmit: {
    color: "blue",
    background: "none",
    border: "none"
  },
  mainBox: {
    width: "100%",
    paddingTop: "20px"
  }

};
// Customizable Area End
