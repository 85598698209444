import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Item, Order } from "./types";
import { handleResponseMessage } from "./helpers/handle-response-message";
import StorageProvider from "framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  cartOrderList: Array<Order>;
  cartOrderItems: Array<Item>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdyenIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiOrdersCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      cartOrderList: [],
      cartOrderItems: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (requestCallDataId === this.getApiOrdersCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.setState({ cartOrderList: responseSuccessWeb.data }, () =>
              this.handleSetAmount(),
            );
          },
          onFail: () => {
            this.setState({ cartOrderList: [] }, () =>
              this.showAlert(
                `Error`,
                `${
                  responseSuccessWeb?.errors
                    ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                    : configJSON.noOrdersToDisplay
                }`,
              ),
            );
          },
        });
      }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  async componentDidMount() {
    const token = await StorageProvider.get("token");
    this.setState({ token }, () => this.getOrders(token));
  }

  handleSetAmount = async () => {
    const order = this.state.cartOrderList[0]?.attributes;

    order &&
      (await StorageProvider.set(
        "value",
        JSON.stringify({
          amount: order.total_fees + order.total_tax,
          currency: configJSON.defaultCurrencyValue,
        }),
      ));
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { filter_by: "scheduled" };
    this.getApiOrdersCallId = requestMessage.messageId;
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckout = (item: Order) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.adyenCheckoutText,
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      data: item,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}
