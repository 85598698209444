import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider.web";
import { handleResponseMessage } from "./helpers/handleResponseMessage";
import {
  IApiCall,
  ICustomerData,
  IHistoryCartOrders,
  Order,
} from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IHistoryCartOrders;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cartOrderId: string;
  cartToken: string;
  cartOrderList: Array<Order>;
  isVisible: boolean;
  cartId: string;
  razorId: string;
  razorAmount: number;
  customerData: ICustomerData;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Razorpayintegration6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiOrdersCallId: string = "";
  createOrderCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cartOrderId: "",
      cartToken: "",
      cartOrderList: [],
      isVisible: false,
      cartId: "",
      razorId: "",
      razorAmount: 0,
      customerData: {
        data: {
          id: "",
          type: "",
          attributes: {
            activated: false,
            country_code: null,
            email: "",
            first_name: "",
            full_phone_number: "",
            last_name: "",
            phone_number: null,
            type: "",
            created_at: "",
            updated_at: "",
            device_id: null,
            unique_auth_id: "",
          },
        },
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (requestCallDataId === this.getApiOrdersCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.setState({ cartOrderList: responseSuccessWeb?.data });
          },
          onFail: () => {
            this.setState({ cartOrderList: [] });
            this.showAlert(
              `Error`,
              `${
                responseSuccessWeb.errors
                  ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                  : configJSON.noDataErrorText
              }`,
            );
          },
        });
      } else if (requestCallDataId === this.createOrderCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.props.history.push("RazorpayintegrationCheckout", {
              data: {
                item: responseSuccessWeb.data,
                token: this.state.cartToken,
                customerData: this.state.customerData,
              },
            });
          },
          onFail: () => {
            this.showAlert(
              `Error`,
              `${
                responseSuccessWeb?.error
                  ? responseSuccessWeb.error
                  : configJSON.createRazorOrderError
              }`,
            );
          },
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const token = await storage.get("token");
    this.setState({ cartToken: token });
    this.getOrders(token);
  }
  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { filter_by: "scheduled" };

    this.getApiOrdersCallId = requestMessage.messageId;
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleRazorApiCall = () => {
    const body = {
      data: {
        amount: `${
          this.state.cartOrderList &&
          (this.state.cartOrderList[0].attributes.total_fees +
            this.state.cartOrderList[0].attributes.total_tax) *
            100
        }`,
        currency: `${configJSON.currencyValue}`,
        notes: {
          key1: "value1",
          key2: "value2",
        },
      },
    };
    this.handleDoCreateObject({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: `${configJSON.createRazorApiEndPoint}/${this.state.cartOrderId}/${configJSON.createOrder}`,
      body: body,
      token: this.state.cartToken,
    });
  };
  handleDoCreateObject = (data: IApiCall) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.createOrderCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleCheckout = async (checkItem: Order) => {
    this.setState(
      {
        cartOrderId: checkItem.id,
        customerData: checkItem.attributes?.customer,
      },
      () => this.handleRazorApiCall(),
    );
  };
  // Customizable Area End
}
