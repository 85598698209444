import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { CometChatUIKit,UIKitSettingsBuilder } from "@cometchat/chat-uikit-react";
import {CometChat} from "@cometchat/chat-sdk-javascript";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chatWithGroup: undefined | CometChat.Group;
  tab: number;
  uid: string;
  uidRegister: string;
  name: string;
  isLoggedIn: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CometchatintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chatWithGroup: undefined,
      tab: 0,
      uid: '',
      isLoggedIn: false,
      uidRegister: '',
      name: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    //create the builder
    const UIKitSettingsInit = new UIKitSettingsBuilder()
      .setAppId(configJSON.appID)
      .setRegion(configJSON.region)
      .setAuthKey(configJSON.authKey)
      .subscribePresenceForFriends()
      .build();

    //Initialize CometChat UIKit
    CometChatUIKit.init(UIKitSettingsInit)
  }

  handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => {
    this.setState({ tab });
  };

  handleUIDChange = (element: ChangeEvent<HTMLInputElement>) => {
    this.setState({ uid: element.target.value });
  };

  handleUIDRegisterChange = (element: ChangeEvent<HTMLInputElement>) => {
    this.setState({ uidRegister: element.target.value });
  };

  handleNameChange = (element: ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: element.target.value });
  };

  handleLogin = () => {
   
    CometChatUIKit.getLoggedinUser().then((user:CometChat.User|any) => {
      if (!user) {
        CometChatUIKit.login(this.state.uid).then((user:CometChat.User|any) => {
          if (user) {
            this.setState({isLoggedIn: true})
          }
        })
      }else {
        //user already logged in
        this.setState({isLoggedIn: true})
      }
    });
  }

  handleRegister = async () => {
    const user :any = new CometChat.User(this.state.uidRegister)
    user.setName(this.state.name)
    const token =  (await CometChatUIKit.createUser(user)).getAuthToken()
    await CometChatUIKit.login(this.state.uidRegister).then((user:CometChat.User|any) => {
      this.setState({isLoggedIn: true})
    })
  }

  handleLogout = () => {
    this.setState({isLoggedIn: false, uid: ''})
    CometChatUIKit.logout()
  }
  // Customizable Area End
}
