import React from "react";

import {
  Container
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

import Razorpay6CheckoutController, {
  Props,
} from "./Razorpay6CheckoutController.web";
import Razorpay6Component from "./Razorpay6Component";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
class Razorpay6Checkout extends Razorpay6CheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" data-test-id="container">
          <Razorpay6Component
            {...this.props}
            handleSuccessResponse={this.handleSuccessResponse}
            data-test-id="checkout"
          />
        </Container>
      </ThemeProvider>
    );
  }
}

export default Razorpay6Checkout;
