import React, { ChangeEvent, FunctionComponent } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStyles } from "./CustomerDetailPopup.web";

export type Props = {
  isReturnItemModalOpen: boolean;
  closeModal: () => void;
  updateInputValue: (event: ChangeEvent<HTMLInputElement>) => void;
  returnOrderlength: string;
  returnOrderbreadth: string;
  returnOrderheight: string;
  returnOrderweight: string;
  validateReturnOrder: () => void;
  isReturnLoading: boolean;
};

const ReturnOrderPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isReturnItemModalOpen}
      onClose={props.closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.isReturnItemModalOpen}>
        <Box className={classes.paper}>
          <Typography variant="h5" className={classes.dimensionHeading}>
            Fill Details
          </Typography>
          <Box>
            <Typography className={classes.inputLable}>
              Length
            </Typography>
            <TextField
              type="text"
              name="returnOrderlength"
              value={props.returnOrderlength || ""}
              onChange={props.updateInputValue}
            />
            <Typography className={classes.inputLable}>
              Breadth
            </Typography>
            <TextField
              type="text"
              name="returnOrderbreadth"
              value={props.returnOrderbreadth || ""}
              onChange={props.updateInputValue}
            />
            <Typography className={classes.inputLable}>
              Height
            </Typography>
            <TextField
              type="text"
              name="returnOrderheight"
              value={props.returnOrderheight || ""}
              onChange={props.updateInputValue}
            />
            <Typography className={classes.inputLable}>
              Weight
            </Typography>
            <TextField
              type="text"
              name="returnOrderweight"
              value={props.returnOrderweight || ""}
              onChange={props.updateInputValue}
            />
          </Box>
          <Button
            onClick={props.validateReturnOrder}
            style={returnOrderWebStyle.buttonWrapper}
            data-test-id="closeDimensionButton">
            {props.isReturnLoading ? (
              <CircularProgress size="1.5rem" color="inherit" />
            ) : (
              "Confirm Return"
            )}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReturnOrderPopup;

const returnOrderWebStyle = {
  buttonWrapper: {
    backgroundColor: "#2297f8",
    margin: "7px 0px 7px 0px",
    padding: "10px 5px 10px 5px",
    borderRadius: "10px",
    color: "#ffffff",
    display: "block",
    width: "100%",
  },
};
