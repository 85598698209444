import React, { ChangeEvent, FunctionComponent } from "react";
import { Modal, Box, Typography, Button, TextField } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const configJSON = require("./config");
import { useStyles } from "./CustomerDetailPopup.web";

export type Props = {
  isUpdatePopupOpen: boolean;
  handleUpdatePopupClose: () => void;
  handelInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  length: string;
  breadth: string;
  height: string;
  weight: string;
  validateOrderUpdate: () => void;
};

const UpdateDetailsPopup: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isUpdatePopupOpen}
      onClose={props.handleUpdatePopupClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={props.isUpdatePopupOpen}>
        <Box className={classes.paper}>
          <Typography variant="h5" className={classes.dimensionHeading}>
            {configJSON.updateOrderDimenensionText}
          </Typography>
          <Box>
            <Typography className={classes.inputLable}>
              {configJSON.textLength}
            </Typography>
            <TextField
              type="text"
              name="length"
              value={props.length || ""}
              onChange={props.handelInputChange}
              data-test-id="inputLength"
            />
            <Typography className={classes.inputLable}>
              {configJSON.textBreadth}
            </Typography>
            <TextField
              type="text"
              name="breadth"
              value={props.breadth || ""}
              onChange={props.handelInputChange}
            />
            <Typography className={classes.inputLable}>
              {configJSON.textHeight}
            </Typography>
            <TextField
              type="text"
              name="height"
              value={props.height || ""}
              onChange={props.handelInputChange}
            />
            <Typography className={classes.inputLable}>
              {configJSON.textWeight}
            </Typography>
            <TextField
              type="text"
              name="weight"
              value={props.weight || ""}
              onChange={props.handelInputChange}
            />
          </Box>
          <Button
            variant="outlined"
            onClick={props.validateOrderUpdate}
            className={classes.doneButton}
            data-test-id="updateDimensionButton">
            {configJSON.done}
          </Button>
          <Button
            variant="outlined"
            onClick={props.handleUpdatePopupClose}
            className={classes.cancelButton}
            data-test-id="closeDimensionButton">
            {configJSON.buttonCancel}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpdateDetailsPopup;
