import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import {
  Container,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  TextField,
  Select,
  MenuItem,
  Box,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import UpdateAddressController, {
  Props,
  configJSON,
} from "./UpdateAddressController.web";
import UpdateDetailsPopup from "./UpdateDetailsPopup.web";

export default class UpdateAddress extends UpdateAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Paper style={webStyle.paperContainer}>
            <Typography align="center" variant="h5" style={webStyle.mainTitle}>
              {configJSON.updateOrderTitle}
            </Typography>
            <TableContainer style={webStyle.checkoutTableContainer}>
              <Table aria-label="simple table" stickyHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>{configJSON.address}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        value={this.state.address}
                        onChange={this.handelInputChange}
                        name="address"
                        data-test-id="address"
                        placeholder={configJSON.addressPlaceholder}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.address2}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        value={this.state.address2}
                        onChange={this.handelInputChange}
                        name="address2"
                        data-test-id="address2"
                        placeholder={configJSON.addressPlaceholder}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.city}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        name="city"
                        value={this.state.city}
                        onChange={this.handelInputChange}
                        data-test-id="city"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.state}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        name="stateName"
                        value={this.state.stateName}
                        onChange={this.handelInputChange}
                        data-test-id="stateName"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.country}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        name="country"
                        value={this.state.country}
                        onChange={this.handelInputChange}
                        data-test-id="country"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.pinCode}</TableCell>
                    <TableCell>
                      <TextField
                        style={webStyle.textField}
                        type="text"
                        name="pinCode"
                        value={this.state.pinCode}
                        onChange={this.handelInputChange}
                        data-test-id="pinCode"
                        placeholder={configJSON.pincodePlaceholder}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.addressType}</TableCell>
                    <TableCell>
                      <InputLabel id="demo-simple-select-label">
                        {configJSON.addressType}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        data-test-id="addressType"
                        value={this.state.addressType}
                        label={configJSON.addressType}
                        name="addressType"
                        onChange={(
                          event: React.ChangeEvent<{
                            value?: string | unknown;
                          }>,
                        ) => this.handleInputSelect(event)}>
                        <MenuItem value={"Home"}>
                          {configJSON.textHome}
                        </MenuItem>
                        <MenuItem value={"Work"}>
                          {configJSON.textWork}
                        </MenuItem>
                        <MenuItem value={"Other"}>
                          {configJSON.textOther}
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell style={webStyle.buttonsContainer}>
                      <Box sx={webStyle.errorTypography}>
                        {this.state.address.length <= 3 ||
                        this.state.address2.length <= 3 ? (
                          <Typography
                            style={webStyle.errorTypography}
                            color="error">
                            {configJSON.addressPlaceholder}
                          </Typography>
                        ) : null}
                      </Box>

                      <Button
                        disabled={
                          this.state.address.length <= 3 ||
                          this.state.address2.length <= 3
                        }
                        data-test-id="doneButton"
                        color="inherit"
                        variant="outlined"
                        onClick={this.onAddingNewAddress}
                        style={webStyle.doneButtonStyle}>
                        {configJSON.done}
                      </Button>
                      <Button
                        data-test-id="cancelButton"
                        color="inherit"
                        variant="outlined"
                        onClick={this.navigateToOrderList}
                        style={webStyle.cancelButtonStyle}>
                        {configJSON.buttonCancel}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
        <UpdateDetailsPopup
          data-test-id={"updateDetailsPopup"}
          isUpdatePopupOpen={this.state.isUpdatePopupOpen}
          handleUpdatePopupClose={this.handleUpdatePopupClose}
          handelInputChange={this.handelInputChange}
          length={this.state.length}
          breadth={this.state.breadth}
          height={this.state.height}
          weight={this.state.weight}
          validateOrderUpdate={this.validateOrderUpdate}
        />
      </ThemeProvider>

      // Customizable Area End
    );
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  updateOrderHeading: {
    textAlign: "center",
    fontSize: "20px",
  },
  mainTitle: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  checkoutTableContainer: {
    maxHeight: "100vh",
  },
  doneButtonStyle: {
    border: "1px solid rgb(98, 0, 238)",
    width: "120px",
    marginRight: "10px",
    color: "green",
  },
  cancelButtonStyle: {
    border: "1px solid rgb(98, 0, 238)",
    color: "red",
    width: "120px",
  },
  textField: {
    width: "-moz-available",
  },
  errorTypography: {
    width: "-moz-available",
    marginBottom: "5px",
    height: "50px",
  },
  buttonsContainer: {
    display: "flex as flex",
    alignItems: "center as center",
    justifyContent: "center as center",
    marginTop: "5px",
  },
};
// Customizable Area End
