Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.postPutApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "easypostintegration";
exports.labelBodyText = "easypostintegration Body";
exports.getShippingCartOrderAPiEndPoint = "bx_block_easypost/orders?filter_by=non_scheduled&page=";
exports.getOrdersAPiEndPoint = "bx_block_easypost/shipment/"
exports.postBuyShipmentEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/buy_shipment"
exports.postCreatePickupEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/create_pickup"
exports.postReturnOrderEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/return_shipment"
exports.postTrackOrderEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/tracking_history_by_shipment_id"
exports.postCancelOrderEndPoint = "bx_block_easypost/shipment/{ORDER_ID}/cancel_order?status=pre_transit"
exports.postKeysEndPoint = "bx_block_easypost/user_info/add_api_keys"
exports.getKeysEndPoint = "bx_block_easypost/user_info"
exports.getOrdersApiContentType = "application/json";
exports.cancelBtnLabel = "No";
exports.buyShipmentBtnLabel = "Yes";

// Customizable Area End