import React from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { ViewProps } from "./Razorpay6Component";
const configJSON = require("./config.js");

const Razorpay6ComponentView: React.FC<ViewProps> = ({
  handlePayment,
  history,
}) => {
  const customerData = history.location.state.data.customerData.data.attributes;
  return (
    <Box style={webStyle.main} data-test-id="boxContainer">
      <Box style={webStyle.paper}>
        <Box>
          <Typography variant="h6" style={webStyle.heading}>
            {configJSON.customerDetailsText}
          </Typography>
          <Box style={webStyle.infoContainer}>
            <Typography variant="body1" style={webStyle.infoSmallHeading}>
              {configJSON.prefillName} :
            </Typography>
            <Typography style={webStyle.infoText} variant="h6">
              {customerData.first_name}
            </Typography>
            <Typography  style={webStyle.infoText} variant="h6">
              {customerData.last_name}
            </Typography>
          </Box>
          <Box  style={webStyle.infoContainer}>
            <Typography variant="body1"  style={webStyle.infoSmallHeading}>
              {configJSON.amountText} :{" "}
            </Typography>
            <Typography  style={webStyle.infoText} variant="h6">
              {history.location.state.data.item.attributes.amount / 100}
            </Typography>
          </Box>
          <Box  style={webStyle.infoContainer}>
            <Typography variant="body1"  style={webStyle.infoSmallHeading}>
              {configJSON.phoneNumberText} :{" "}
            </Typography>
            <Typography  style={webStyle.infoText} variant="h6">
              {customerData.full_phone_number}
            </Typography>
          </Box>
        </Box>
        <Box  style={webStyle.paymentTextContainer}>
          <Typography variant="body1"  style={webStyle.paymentText}>
            {configJSON.razorPayNote}
          </Typography>
          <Button
            variant="contained"
            onClick={handlePayment}
            data-test-id="paymentHandle">
            {configJSON.checkOutButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const webStyle = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "10px",
  },
  heading: {
    color: "rgb(98, 0, 238)",
    textDecoration: "underline",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoText: {
    textTransform: "capitalize" as "capitalize",
    margin: "0 4px",
  },
  paymentText: {
    margin: "10px 0",
    fontSize: "18px",
    color: "black",
  },
  paymentTextContainer: {
    margin: "50px 0",
    height: "50px",
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
    flexDirection: "column" as "column",
  },
  infoSmallHeading: { width: "130px",
   textAlign: "left" as "left" },
}
export default Razorpay6ComponentView;
