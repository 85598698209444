Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE";

exports.getOrdersApiEndPoint = "shopping_cart/orders";
exports.createOrderItemApiEndPoint = "shopping_cart/order_items";
exports.deleteOrderItemApiEndPoint = "shopping_cart/orders";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
// Customizable Area End
