import {
  ICustomerDataThree,
  ICustomerDataTwo,
  TrackOrderType,
} from "../src/types/types";

export const mockUpdateHistory = {
  location: {
    state: {
      data: {
        customerData: {
          id: 5,
          type: "order",
          attributes: {
            id: 1,
            type: "",
            address: {
              data: {
                id: "1",
                type: "address",
                attributes: {
                  latitude: null,
                  longitude: null,
                  address: "",
                  address_type: "",
                  city: "",
                  pincode: 1212,
                  state: "",
                  address2: "",
                  country: "India",
                },
              },
            },
            length: "1",
            height: "1",
            breadth: "1",
            weight: "1",
          },
        },
      },
    },
  },
};
export const mockUpdateResponse = {
  success: {
    message: "responce message",
    data: {
      id: "1",
      type: "address",
      attributes: {
        latitude: null,
        longitude: null,
        address: "dev",
        address_type: "Home",
        city: "New City",
        pincode: 456443,
        state: "MP",
        address2: "med",
        country: "india",
      },
    },
    meta: {
      message: "Address Updated Successfully",
    },
  },
  statusData: { status: 404 },
  message: "Something went wrong!",
  errors: { errors: "something error occured!" },
  error: "Something went wrong!",
  data: {
    meassage: "not found!",
  },
};

export const trackOrderData: TrackOrderType = {
  data: [
    {
      tracking_data: {
        track_status: 1,
        shipment_status: 7,
        shipment_track: [
          {
            id: 236612717,
            awb_code: "141123221084922",
            courier_company_id: 51,
            shipment_id: 236612717,
            order_id: 237157589,
            pickup_date: "2022-07-18 20:28:00",
            delivered_date: "2022-07-19 11:37:00",
            weight: "0.30",
            packages: 1,
            current_status: "Delivered",
            delivered_to: "Chittoor",
            destination: "Chittoor",
            consignee_name: "",
            origin: "Banglore",
            courier_agent_details: null,
            courier_name: "Xpressbees Surface",
            edd: null,
            pod: "Available",
            pod_status:
              "https://s3-ap-southeast-1.amazonaws.com/kr-shipmultichannel/courier/51/pod/141123221084922.png",
          },
        ],
        shipment_track_activities: [
          {
            date: "2022-07-19 11:37:00",
            status: "DLVD",
            activity: "Delivered",
            location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
            sr_status: "7",
            sr_status_label: "DELIVERED",
          },
        ],
        track_url: "https://shiprocket.co//tracking/141123221084922",
        etd: "2022-07-20 19:28:00",
        qc_response: {
          qc_image: "",
          qc_failed_reason: "",
        },
      },
    },
  ],
};

export const mockShipModalPop = {
  id: "1",
  attributes: {
    ship_rocket_order_id: "111111111",
    ship_rocket_awb_code: "22222",
  },
};
export const mockShipModalData = {
  shipments: {
    id: "10",
  },
};
export const mockCustomerDataItem = {
  id: 1,
  attributes: {
    customer: {
      data: {
        attributes: {
          first_name: "John",
          last_name: "Doe",
          email: "johndoe@example.com",
          full_phone_number: "1234567890",
          created_at: "2023-06-27",
        },
      },
    },
    status: "Active",
  },
};
export const invoiceMockData = {
  data: {
    is_invoice_created: true,
    invoice_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/2765546/invoices/Retail000134a4fbef7-be40-4a7b-a1f0-c6dd7f4708f3.pdf",
    not_created: [],
    irn_no: "",
  },
  status: "200",
};

export const shipmentMockData1 = {
  message: "Shipment Details not found",
  data: {
    status_code: 422,
    errors: {
      billing_country: ["The billing country field is required"],
      billing_phone: ["The billing phone field is required."],
    },
    message: "testing",
  },
};

export const shipmentRequestData = {
  data: {
    order_id: 361312841,
    shipment_id: 360686277,
    status: "CANCELLATION REQUESTED",
    status_code: 18,
    onboarding_completed_now: 0,
    awb_code: "14326384801742",
    courier_company_id: 24,
    courier_name: "Xpressbees 2kg",
  },
};

export const shipmentRequestErrorData = {
  data: {
    message: "Oops! Invalid Data.",
    errors: {
      billing_country: ["The billing country field is required"],
      billing_phone: ["The billing phone field is required."],
    },
    status_code: 422,
  },
};

export const cancelOrderSuccessData = {
  data: {
    status_code: 200,
    message: "Order cancelled successfully.",
  },
};

export const cancelOrderErrorData = {
  data: {
    message: "Cannot cancel order when shipment status is Canceled",
    status_code: 400,
  },
};

export const shipmentDataUpdate = {
  data: {
    "id": 355976034,
    "channel_id": 3081599,
    "last_mile_awb": null,
    "channel_name": "CUSTOM",
    "base_channel_code": "CS",
    "is_international": 0,
    "is_document": 0,
    "channel_order_id": "SCOD1",
    "customer_name": "sanskar songara",
    "customer_email": "sanskar@yopmail.com",
    "customer_phone": "9876543210",
    "customer_address": "1404, Tower 24, BPTP Terra",
    "customer_address_2": "",
    "customer_city": "Gurgaon",
    "customer_state": "Haryana",
    "customer_pincode": "122001",
    "customer_country": "India",
    "pickup_code": "122001",
    "pickup_location":
      "1404, Tower 24, BPTP Terra,Gurgaon, Sector 37 D,Gurgaon,Haryana,122001",
    "pickup_location_id": 2850367,
    "pickup_id": "",
    "ship_type": "",
    "courier_mode": "",
    "currency": "INR",
    "country_code": 99,
    "exchange_rate_usd": 0,
    "exchange_rate_inr": 0,
    "state_code": 1486,
    "payment_status": "",
    "delivery_code": "122001",
    "total": 1,
    "total_inr": 0,
    "total_usd": 0,
    "net_total": "0.00",
    "other_charges": "0.00",
    "other_discounts": "0.00",
    "giftwrap_charges": 0,
    "expedited": 0,
    "sla": "2 days",
    "cod": 0,
    "tax": 0,
    "total_kerala_cess": "",
    "discount": 0,
    "status": "INVOICED",
    "sub_status": null,
    "status_code": 2,
    "master_status": "",
    "payment_method": "prepaid",
    "purpose_of_shipment": 0,
    "channel_created_at": "2 Jun 2023 06:29 AM",
    "created_at": "5 Jun 2023 10:58 AM",
    "order_date": "2 Jun 2023",
    "updated_at": "4 Jul 2023 01:41 PM",
    "products": [
      {
        id: 519221772,
        order_id: 355976034,
        product_id: 128510101,
        name: "demo",
        sku: "1-1-dummy_sku",
        description: null,
        channel_order_product_id: "519221772",
        channel_sku: "1-1-dummy_sku",
        hsn: "",
        model: null,
        manufacturer: null,
        brand: null,
        color: null,
        size: null,
        custom_field: "",
        custom_field_value: "",
        custom_field_value_string: "",
        weight: 0,
        dimensions: "0x0x0",
        price: 0,
        cost: 0,
        mrp: 0,
        quantity: 2,
        returnable_quantity: 0,
        tax: 0,
        status: 1,
        net_total: 0,
        discount: 0,
        product_options: [],
        selling_price: 0,
        tax_percentage: 0,
        discount_including_tax: 0,
        channel_category: "Default Category",
        packaging_material: "",
        additional_material: "",
        is_free_product: "",
      },
    ],
    "invoice_no": "Retail00015",
    "invoice_date": "2023-07-04 13:41:20",
    "shipments": {
      id: 355351715,
      order_id: 355976034,
      order_product_id: null,
      channel_id: 3081599,
      code: "",
      cost: "0.00",
      tax: "0.00",
      awb: null,
      last_mile_awb: "",
      rto_awb: "",
      awb_assign_date: null,
      etd: "",
      delivered_date: "",
      quantity: 1,
      cod_charges: "0.00",
      number: null,
      name: null,
      order_item_id: null,
      weight: 48,
      volumetric_weight: 19.458,
      dimensions: "45.000x46.000x47.000",
      comment: "",
      courier: "",
      courier_id: "",
      manifest_id: "",
      manifest_escalate: false,
      status: "PENDING",
      isd_code: "+91",
      created_at: "5th Jun 2023 10:58 AM",
      updated_at: "11th Jul 2023 12:48 PM",
      pod: null,
      eway_bill_number: "-",
      eway_bill_date: null,
      length: 45,
      breadth: 46,
      height: 47,
      rto_initiated_date: "",
      rto_delivered_date: "",
      shipped_date: "",
      package_images: "",
      is_rto: false,
      eway_required: false,
      invoice_link:
        "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/&invoices/Retail0001556b0cdbc-daac-4964-a49e-3cd154154625.pdf",
      is_darkstore_courier: 0,
      courier_custom_rule: "",
      is_single_shipment: true,
    },
    "awb_data": {
      awb: "",
      applied_weight: "",
      charged_weight: "",
      billed_weight: "",
      routing_code: "",
      rto_routing_code: "",
      charges: {
        zone: "",
        cod_charges: "",
        applied_weight_amount: "",
        freight_charges: "",
        applied_weight: "",
        charged_weight: "",
        charged_weight_amount: "",
        charged_weight_amount_rto: "",
        applied_weight_amount_rto: "",
        service_type_id: "",
      },
    },
    "order_insurance": {
      insurance_status: "No",
      policy_no: "N/A",
      claim_enable: false,
    },
    "return_pickup_data": "",
    "company_logo": null,
    "allow_return": 0,
    "is_return": 0,
    "is_incomplete": 0,
    "errors": [],
    "payment_code": null,
    "coupon_is_visible": false,
    "coupons": "",
    "billing_city": "",
    "billing_name": "",
    "billing_email": "",
    "billing_phone": "",
    "billing_alternate_phone": "",
    "billing_state_name": "",
    "billing_address": "",
    "billing_country_name": "",
    "billing_pincode": "",
    "billing_address_2": "",
    "billing_mobile_country_code": "",
    "isd_code": "",
    "billing_state_id": "",
    "billing_country_id": "",
    "freight_description": "Forward charges",
    "reseller_name": "",
    "shipping_is_billing": 0,
    "company_name": null,
    "shipping_title": "",
    "allow_channel_order_sync": false,
    "uib-tooltip-text": "Re-fetch orders with updated details",
    "api_order_id": null,
    "allow_multiship": 0,
    "other_sub_orders": [],
    "others": {
      weight: "48",
      quantity: 1,
      client_id: null,
      self_ship: false,
      ship_date: null,
      vendor_id: null,
      buyer_psid: null,
      dimensions: "45x46x47",
      order_items: [
        {
          sku: "1-1-dummy_sku",
          name: "demo",
          units: 2,
          selling_price: 0,
        },
      ],
      vendor_code: null,
      company_name: null,
      invoice_date: null,
      currency_code: null,
      package_count: 1,
      reseller_name: null,
      customer_gstin: null,
      invoice_amount: null,
      invoice_number: null,
      transaction_id: "",
      eway_bill_number: "",
      shipping_charges: "0",
      is_order_verified: 0,
      order_verified_at: "",
      shipping_service_type: "",
      transaction_date_time: "",
      packaging_unit_details: null,
      store_rush_promise_sla: "",
      billing_alternate_phone: "",
      customer_isd_code: "+91",
    },
    "is_order_verified": 2,
    "extra_info": {
      order_type: "",
      rto_reason: "Prepaid orders have low RTO risk.",
      is_document: 0,
      order_metadata: {
        type: "create",
        device: false,
        platform: "NONE",
        request_type: "external",
        is_insurance_opt: false,
      },
      rto_pred_enable: "0",
      rto_reason_code: "R006",
      amazon_dg_status: false,
      applied_sr_challan: false,
      bluedart_dg_status: false,
      is_promise_assured: false,
      other_courier_dg_status: false,
      insurace_opted_at_order_creation: false,
    },
    "package_list": { packages: "", selected: false, select_item: false },
    "dup": 0,
    "is_blackbox_seller": false,
    "shipping_method": "SR",
    "refund_detail": [],
    "pickup_address": {
      id: 2850367,
      pin_code: "122001",
      pickup_code: "Sahil",
      name: "Sahil",
      phone: "9971107229",
      phone_verified: 1,
      address: "1404, Tower 24, BPTP Terra",
      address_2: "Gurgaon, Sector 37 D",
      city: "Gurgaon",
      state: "Haryana",
      country: "India",
      email: "sahil.gupta@builder.ai",
      lat: "28.4482",
      long: "77.0192",
      new: 1,
    },
    "eway_bill_number": "",
    "eway_bill_url": "",
    "eway_required": false,
    "irn_no": "",
    "engage": null,
    "seller_can_edit": false,
    "seller_can_cancell": false,
    "is_post_ship_status": false,
    "order_tag": "",
    "qc_status": "",
    "qc_reason": "",
    "qc_image": "",
    "product_qc": [],
    "courier_qc_image": [],
    "seller_request": null,
    "change_payment_mode": true,
    "etd_date": null,
    "out_for_delivery_date": null,
    "delivered_date": null,
    "remittance_date": "",
    "remittance_utr": "",
    "remittance_status": "",
    "shipping_bill": "",
    "quick_pick": "",
    "shipping_bill_number": "",
    "exchange_awb": null,
    "exchange_status": null,
    "rto_prediction": "",
    "can_edit_dimension": true,
  },
};

export const awbResponseData = {
  response: {
    data: {
      awb_code: "777866654",
    },
  },
};

export const successAwbResponse = {
  awb_assign_status: 1,
  response: {
    data: {
      courier_company_id: 14,
      cod: 0,
      order_id: 370498571,
      shipment_id: 369871994,
      awb_code_status: 1,
      awb_code: {
        awb: "7777383833",
      },
      assigned_date_time: {
        date: "2023-07-06 17:35:46.000000",
        timezone_type: 3,
        timezone: "Asia/Kolkata",
      },
      applied_weight: 1,
      company_id: 2765546,
      courier_name: "Ecom Express Surface",
      child_courier_name: null,
      freight_charges: 141.43,
      invoice_no: "Retail00018",
      transporter_id: "07AADCE1344F1Z2",
      transporter_name: "Ecom Express",
      shipped_by: {
        shipper_company_name: "Sahil",
        shipper_address_1: "1404, Tower 24, BPTP Terra",
        shipper_address_2: "Gurgaon, Sector 37 D",
        shipper_city: "Gurgaon",
        shipper_state: "Haryana",
        shipper_country: "India",
        shipper_postcode: "122001",
        shipper_first_mile_activated: 0,
        shipper_phone: "9971107229",
        lat: "28.4482",
        long: "77.0192",
        shipper_email: "sahil.gupta@builder.ai",
        extra_info: {
          source: 1,
          boat_delhivery_clientware_id:
            "BOAT SR SURFACE-Gurgaon-122001-Builder.ai-2765546-2850367",
        },
        rto_company_name: "Sahil",
        rto_address_1: "1404, Tower 24, BPTP Terra",
        rto_address_2: "Gurgaon, Sector 37 D",
        rto_city: "Gurgaon",
        rto_state: "Haryana",
        rto_country: "India",
        rto_postcode: "122001",
        rto_phone: "9971107229",
        rto_email: "sahil.gupta@builder.ai",
      },
    },
  },
  no_pickup_popup: 0,
  quick_pick: 0,
  message: "success",
};

export const mockLabelData = {
  data: {
    label_created: 1,
    label_url:
      "https://kr-shipmultichannel-mum.s3.ap-south-1.amazonaws.com/2765546/labels/shipping-label-360686277-14326384801742.pdf",
    response: "Label has been created and uploaded successfully!",
    not_created: [],
  },
  status: "200",
};

export const mockFailedLabelData = {
  data: {
    label_created: 1,
    label_url: "",
    response: "Label has been created and uploaded successfully!",
    not_created: [],
  },
  status: "404",
};

export const pickUpData = {
  pickup_status: 1,
  response: {
    pickup_scheduled_date: "2023-07-07 09:00:00",
    status: 3,
    pickup_generated_date: "2023-07-06T13:06:51.389507Z",
    data: "Pickup is confirmed by Ecom Express Surface For AWB :- 3183089333",
  },
};

export const failedPickUpData = {
  message: "Awb not Assigned",
  status_code: 400,
};

export const failedMenifestData = {
  data: {
    message: "Manifest already generated.",
    status_code: 400,
    already_manifested_shipment_ids: ["369871994"],
  },
  status: "400",
};

export const successMenifestData = {
  data: {
    status: 1,
    manifest_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/2765546/manifest/MANIFEST-0003.pdf",
    error: [],
  },
  status: "200",
};

export const mockDataCustomerDetail = {
  attributes: {
    customer: {
      data: {
        attributes: {
          email: "test@test.com",
        },
      },
    },
    order_items: {
      id: "1",
      data: [
        {
          attributes: {
            price: "123",
            quantity: "12",
          },
        },
      ],
    },
    catalogue: {
      data: {
        attributes: {
          name: "Name",
        },
      },
    },
    ship_rocket_order_id: "9",
    ship_rocket_awb_code: "22222",
  },
};

export const mockPropsDataTwo: ICustomerDataThree = {
  id: "10",
  type: "order",
  attributes: {
    status: "scheduled",
    total_fees: 1.0,
    total_items: 1,
    total_tax: 0.0,
    source: null,
    length: 12.0,
    breadth: 16.0,
    weight: 16.0,
    height: 12.0,
    ship_rocket_order_id: "370498571",
    ship_rocket_shipment_id: "369871994",
    ship_rocket_awb_code: "3183089333",
    ship_rocket_label_url: null,
    ship_rocket_manifest_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/manifest/c_2765546/print_manifests/2850367_ecom-express-surface__JkpYo1688648977.pdf",
    ship_rocket_invoice_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/2765546/invoices/Retail0001820822cca-eb95-4082-9ced-ce663a320d3c.pdf",
    is_request_pickup: true,
    customer: {
      data: {
        id: "1",
        type: "account",
        attributes: {
          activated: true,
          country_code: null,
          email: "sanskar@yopmail.com",
          first_name: "sanskar",
          full_phone_number: "9755853847",
          last_name: "songara",
          phone_number: null,
          type: "EmailAccount",
          created_at: "2023-06-06T11:37:47.580Z",
          updated_at: "2023-06-06T11:37:47.580Z",
          device_id: null,
          unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
        },
      },
    },
    address: {
      data: {
        id: "142",
        type: "address",
        attributes: {
          latitude: null,
          longitude: null,
          address: "Fountain",
          address_type: "Other",
          city: "Nagpur",
          pincode: 4251672,
          state: "Maharashtra",
          address2: " Mihan",
          country: "India",
        },
      },
    },
    order_items: {
      data: [],
    },
  },
};

export const mockDataCustomerDetailTwo: ICustomerDataTwo = {
  id: "10",
  type: "order",
  attributes: {
    status: "scheduled",
    total_fees: 1.0,
    total_items: 1,
    total_tax: 0.0,
    source: null,
    length: 12.0,
    breadth: 16.0,
    weight: 16.0,
    height: 12.0,
    ship_rocket_order_id: "370498571",
    ship_rocket_shipment_id: "369871994",
    ship_rocket_awb_code: "3183089333",
    ship_rocket_label_url: null,
    ship_rocket_manifest_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/manifest/c_2765546/print_manifests/2850367_ecom-express-surface__JkpYo1688648977.pdf",
    ship_rocket_invoice_url:
      "https://s3-ap-south-1.amazonaws.com/kr-shipmultichannel-mum/2765546/invoices/Retail0001820822cca-eb95-4082-9ced-ce663a320d3c.pdf",
    is_request_pickup: true,
    customer: {
      data: {
        id: "1",
        type: "account",
        attributes: {
          activated: true,
          country_code: null,
          email: "sanskar@yopmail.com",
          first_name: "sanskar",
          full_phone_number: "9755853847",
          last_name: "songara",
          phone_number: null,
          type: "EmailAccount",
          created_at: "2023-06-06T11:37:47.580Z",
          updated_at: "2023-06-06T11:37:47.580Z",
          device_id: null,
          unique_auth_id: "SmnhkFR9yGkEpzFokgY5uwtt",
        },
      },
    },
    address: {
      data: {
        id: "142",
        type: "address",
        attributes: {
          latitude: null,
          longitude: null,
          address: "Fountain",
          address_type: "Other",
          city: "Nagpur",
          pincode: 4251672,
          state: "Maharashtra",
          address2: " Mihan",
          country: "India",
        },
      },
    },
    order_items: {
      data: [
        {
          id: "58",
          type: "order_item",
          attributes: {
            price: 1.0,
            quantity: 1,
            taxable: true,
            taxable_value: 0.0,
            other_charges: null,
            catalogue: {
              data: {
                id: "25",
                type: "catalogue",
                attributes: {
                  sub_category: {
                    id: 1,
                    name: "Pre Primary (kg)",
                    created_at: "2023-05-26T10:41:53.313Z",
                    updated_at: "2023-05-26T10:41:53.313Z",
                    parent_id: null,
                    rank: null,
                  },
                  brand: null,
                  tags: [],
                  reviews: [],
                  name: "demo",
                  sku: "dummy_sku",
                  description: "demo description",
                  manufacture_date: "2000-12-31T00:00:00.000Z",
                  length: null,
                  breadth: null,
                  height: null,
                  stock_qty: null,
                  availability: null,
                  weight: null,
                  price: null,
                  recommended: null,
                  on_sale: null,
                  sale_price: "1.0",
                  discount: null,
                  category: {
                    id: "1",
                    type: "category",
                    attributes: {
                      id: 1,
                      name: "K12",
                      dark_icon: null,
                      dark_icon_active: null,
                      dark_icon_inactive: null,
                      light_icon: null,
                      light_icon_active: null,
                      light_icon_inactive: null,
                      rank: null,
                      created_at: "2023-05-26T10:41:53.259Z",
                      updated_at: "2023-05-26T10:41:53.259Z",
                      selected_sub_categories: null,
                    },
                  },
                  images: null,
                  average_rating: 0,
                  catalogue_variants: [],
                },
              },
            },
          },
        },
      ],
    },
  },
};
export const mockDataCustomerDetails: ICustomerDataTwo = {
  id: "14",
  type: "",
  attributes: {
    status: "",
    total_fees: 11,
    total_items: 11,
    total_tax: 1,
    source: null,
    length: 12,
    breadth: 21,
    weight: 12,
    height: 21,
    ship_rocket_order_id: "",
    ship_rocket_shipment_id: "3999999",
    ship_rocket_awb_code: "3999999999",
    ship_rocket_label_url: null,
    ship_rocket_manifest_url: "manifest.pdf",
    ship_rocket_invoice_url: "invoice.pdf",
    is_request_pickup: false,
    customer: {
      data: {
        id: "11",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "test@test.com",
          first_name: "test",
          full_phone_number: "9876543211",
          last_name: "test",
          phone_number: null,
          type: "account",
          created_at: "2023",
          updated_at: "2023",
          device_id: null,
          unique_auth_id: "qwertyyyyyyqwqw",
        },
      },
    },
    address: {
      data: {
        id: "100",
        type: "",
        attributes: {
          latitude: null,
          longitude: null,
          address: "new add",
          address_type: "Home",
          city: "city",
          pincode: 12121,
          state: "Mh",
          address2: " Mn",
          country: "Country",
        },
      },
    },
    order_items: {
      data: [
        {
          id: "12",
          type: "",
          attributes: {
            price: 2,
            quantity: 2,
            taxable: false,
            taxable_value: 1,
            other_charges: null,
            catalogue: {
              data: {
                id: "12",
                type: "aa",
                attributes: {
                  sub_category: {
                    id: 12,
                    name: "name",
                    created_at: "2222",
                    updated_at: "2222",
                    parent_id: null,
                    rank: null,
                  },
                  brand: null,
                  tags: [],
                  reviews: [],
                  name: "test",
                  sku: "test_sku",
                  description: "aaaaaa",
                  manufacture_date: "aaaaaaaa",
                  length: null,
                  breadth: null,
                  height: null,
                  stock_qty: null,
                  availability: null,
                  weight: null,
                  price: null,
                  recommended: null,
                  on_sale: null,
                  sale_price: "111",
                  discount: null,
                  category: {
                    id: "11",
                    type: "caaaa",
                    attributes: {
                      id: 1,
                      name: "A12",
                      dark_icon: null,
                      dark_icon_active: null,
                      dark_icon_inactive: null,
                      light_icon: null,
                      light_icon_active: null,
                      light_icon_inactive: null,
                      rank: null,
                      created_at: "2232",
                      updated_at: "2223",
                      selected_sub_categories: null,
                    },
                  },
                  images: null,
                  average_rating: 1,
                  catalogue_variants: [],
                },
              },
            },
          },
        },
      ],
    },
  },
};

export const orderItemsMock = [
  {
    id: "1",
    type: "datatest",
    attributes: {
      price: 1,
      quantity: 1,
      taxable: false,
      taxable_value: 12,
      other_charges: null,
      catalogue: {
        data: {
          id: "2",
          type: "check",
          attributes: {
            sub_category: {
              id: 1,
              name: "dev",
              created_at: "20-11-2023",
              updated_at: "20-7-2023",
            },
            brand: null,
            name: "name",
            sku: "string",
            description: "string",
            manufacture_date: "string",
            sale_price: "string",
            discount: null,
            category: {
              id: "string",
              type: "string",
              attributes: {
                id: 1,
                name: "string",
                created_at: "string",
                updated_at: "string",
              },
            },
            average_rating: 12,
          },
        },
      },
    },
  },
];

export const mockReturnSuccessData = {
  data: {
    order_id: 170872392,
    shipment_id: 170411259,
    status: "RETURN PENDING",
    status_code: 21,
    company_name: "shiprocket",
  },
};

export const mockReturnErrorData = {
  data: {
    message: "Oops! Invalid Data.",
    errors: {
      order_id: ["The order id has already been taken."],
    },
    status_code: 422,
  },
};

export const ShipmentMockData: ICustomerDataTwo = {
  id: "14",
  type: "",
  attributes: {
    status: "",
    total_fees: 11,
    total_items: 11,
    total_tax: 1,
    source: null,
    length: 12,
    breadth: 21,
    weight: 12,
    height: 21,
    ship_rocket_order_id: "",
    ship_rocket_shipment_id: "3999999",
    ship_rocket_awb_code: "",
    ship_rocket_label_url: null,
    ship_rocket_manifest_url: "manifest.pdf",
    ship_rocket_invoice_url: "invoice.pdf",
    is_request_pickup: false,
    customer: {
      data: {
        id: "11",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "test@test.com",
          first_name: "test",
          full_phone_number: "9876543211",
          last_name: "test",
          phone_number: null,
          type: "account",
          created_at: "2023",
          updated_at: "2023",
          device_id: null,
          unique_auth_id: "qwertyyyyyyqwqw",
        },
      },
    },
    address: {
      data: {
        id: "100",
        type: "",
        attributes: {
          latitude: null,
          longitude: null,
          address: "new add",
          address_type: "Home",
          city: "city",
          pincode: 12121,
          state: "Mh",
          address2: " Mn",
          country: "Country",
        },
      },
    },
    order_items: {
      data: [
        {
          id: "12",
          type: "",
          attributes: {
            price: 2,
            quantity: 2,
            taxable: false,
            taxable_value: 1,
            other_charges: null,
            catalogue: {
              data: {
                id: "12",
                type: "aa",
                attributes: {
                  sub_category: {
                    id: 12,
                    name: "name",
                    created_at: "2222",
                    updated_at: "2222",
                    parent_id: null,
                    rank: null,
                  },
                  brand: null,
                  tags: [],
                  reviews: [],
                  name: "test",
                  sku: "test_sku",
                  description: "aaaaaa",
                  manufacture_date: "aaaaaaaa",
                  length: null,
                  breadth: null,
                  height: null,
                  stock_qty: null,
                  availability: null,
                  weight: null,
                  price: null,
                  recommended: null,
                  on_sale: null,
                  sale_price: "111",
                  discount: null,
                  category: {
                    id: "11",
                    type: "caaaa",
                    attributes: {
                      id: 1,
                      name: "A12",
                      dark_icon: null,
                      dark_icon_active: null,
                      dark_icon_inactive: null,
                      light_icon: null,
                      light_icon_active: null,
                      light_icon_inactive: null,
                      rank: null,
                      created_at: "2232",
                      updated_at: "2223",
                      selected_sub_categories: null,
                    },
                  },
                  images: null,
                  average_rating: 1,
                  catalogue_variants: [],
                },
              },
            },
          },
        },
      ],
    },
  },
};

export const ShipmentMockDataTwo: ICustomerDataTwo = {
  id: "14",
  type: "",
  attributes: {
    status: "",
    total_fees: 11,
    total_items: 11,
    total_tax: 1,
    source: null,
    length: 12,
    breadth: 21,
    weight: 12,
    height: 21,
    ship_rocket_order_id: "67687878787",
    ship_rocket_shipment_id: "3999999",
    ship_rocket_awb_code: "67676878",
    ship_rocket_label_url: null,
    ship_rocket_manifest_url: "manifest.pdf",
    ship_rocket_invoice_url: "invoice.pdf",
    is_request_pickup: false,
    customer: {
      data: {
        id: "11",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "test@test.com",
          first_name: "test",
          full_phone_number: "9876543211",
          last_name: "test",
          phone_number: null,
          type: "account",
          created_at: "2023",
          updated_at: "2023",
          device_id: null,
          unique_auth_id: "qwertyyyyyyqwqw",
        },
      },
    },
    address: {
      data: {
        id: "100",
        type: "",
        attributes: {
          latitude: null,
          longitude: null,
          address: "new add",
          address_type: "Home",
          city: "city",
          pincode: 12121,
          state: "Mh",
          address2: " Mn",
          country: "Country",
        },
      },
    },
    order_items: {
      data: [
        {
          id: "12",
          type: "",
          attributes: {
            price: 2,
            quantity: 2,
            taxable: false,
            taxable_value: 1,
            other_charges: null,
            catalogue: {
              data: {
                id: "12",
                type: "aa",
                attributes: {
                  sub_category: {
                    id: 12,
                    name: "name",
                    created_at: "2222",
                    updated_at: "2222",
                    parent_id: null,
                    rank: null,
                  },
                  brand: null,
                  tags: [],
                  reviews: [],
                  name: "test",
                  sku: "test_sku",
                  description: "aaaaaa",
                  manufacture_date: "aaaaaaaa",
                  length: null,
                  breadth: null,
                  height: null,
                  stock_qty: null,
                  availability: null,
                  weight: null,
                  price: null,
                  recommended: null,
                  on_sale: null,
                  sale_price: "111",
                  discount: null,
                  category: {
                    id: "11",
                    type: "caaaa",
                    attributes: {
                      id: 1,
                      name: "A12",
                      dark_icon: null,
                      dark_icon_active: null,
                      dark_icon_inactive: null,
                      light_icon: null,
                      light_icon_active: null,
                      light_icon_inactive: null,
                      rank: null,
                      created_at: "2232",
                      updated_at: "2223",
                      selected_sub_categories: null,
                    },
                  },
                  images: null,
                  average_rating: 1,
                  catalogue_variants: [],
                },
              },
            },
          },
        },
      ],
    },
  },
};
export const mockErrorResp = {
  status_code: 404,
  error: "something went wrong",
};
