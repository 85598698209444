import React from "react";

import {
  Container,
  Button,
  Typography,
  // Customizable Area Start
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Order } from "./types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AdyenIntegrationController, {
  Props,
  configJSON,
} from "./AdyenIntegrationController.web";

export default class AdyenIntegration extends AdyenIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Typography variant="h5" align="center" style={webStyle.cartHeading}>{configJSON.textCartPage}</Typography>
        <Container maxWidth="md">
          <Paper style={webStyle.paper}>
            <TableContainer style={webStyle.tableContainer}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow >
                    <TableCell style={webStyle.tableHeadings}>{configJSON.idText}</TableCell>
                    <TableCell style={webStyle.tableHeadings}>{configJSON.totalFeesText}</TableCell>
                    <TableCell style={webStyle.tableHeadings}>{configJSON.totalItemsText}</TableCell>
                    <TableCell style={webStyle.tableHeadings}>{configJSON.totalTaxText}</TableCell>
                    <TableCell style={webStyle.tableHeadings}>{configJSON.customerText}</TableCell>
                    <TableCell align="right" style={webStyle.tableHeadings}>{configJSON.actionText}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.cartOrderList &&
                    this.state.cartOrderList.length !== 0 ? (
                    this.state.cartOrderList.map((item:Order, index: number) => {
                      const value = item.attributes;
                      const orderItemId: string = item.id;
                      return (
                        <TableRow key={orderItemId} data-test-id="orderList">
                          <TableCell scope="row">{orderItemId}</TableCell>
                          <TableCell>{value.total_fees}</TableCell>
                          <TableCell>{value.total_items}</TableCell>
                          <TableCell>{value.total_tax}</TableCell>
                          <TableCell>
                            {`${value.customer.data.attributes.first_name} ${value.customer.data.attributes.last_name}`}
                          </TableCell>
                          <TableCell align="right">

                            <Button
                              data-test-id={`checkout-${index}`}
                              variant="outlined"
                              color="default"
                              onClick={()=>this.handleCheckout(item)}
                            >
                              {configJSON.checkOutButtonTextTwo}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography style={webStyle.note}>
                      {configJSON.noOrdersToDisplay}
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cartHeading:{color:"rgb(98, 0, 238)"},
  tableContainer: {
    maxHeight: 440,
  },
  tableHeadings:{
color:"rgb(98, 0, 238)",
textTransform:"capitalize" as "capitalize"
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalAddButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeModalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  paperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  note: {
    width: "100%" as "100%",
    textAlign: "center" as "center",
  },
};
// Customizable Area End
