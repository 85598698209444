export const CountryList = [
  {
    code: "GB",
    currency: "GBP",
    id: "77",
    name: "United Kingdom",
  },
  {
    code: "US",
    currency: "USD",
    id: "233",
    name: "United States",
  },
  { code: "DE", currency: "EUR", id: "57", name: "Germany" },
];
