import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { CountryList } from "./CountryList";
import AdyenCheckout from "@adyen/adyen-web";
import { RefObject, createRef } from "react";
import {
  ICountryList,
  IDataGetPayment,
  IPayData,
  IResultData,
  ISessionResp,
  ISubmitBody,
  Order,
  PaymentMethod,
} from "./types";
import { handleResponseMessage } from "./helpers/handle-response-message";
import StorageProvider from "framework/src/StorageProvider";
import { type } from "os";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  countryList: ICountryList[];
  selectedCountry: string;
  settlementCurrency: string;
  processingCurrency: string;
  processingAmount: number;
  paymentMethods: PaymentMethod[];
  orderItem: Order[];
  createdSession: ISessionResp;
  resultData: IResultData;
  reference: string;
  isStatus: boolean;
  paymentUrl: string;
  isLoaderVisible: boolean;
  redirectResult: string;
  paymentType: string;
  pspReference: string;
  token: string;
  value: {
    amount: number;
    currency: string;
  };
  baseUrl: string;
  processingCurrencyArr: string[];
  isNote: boolean;
  merchantAccount:string,
  clientId:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdyenCheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  paymentContainer: RefObject<unknown>;
  getSessionCallId: string = "";
  getPaymentCallId: string = "";
  submitPaymentCallId: string = "";
  getPaymentDetailsCallId: string = "";
  getWebhookDetailsCallId: string = "";
  getConvertedValueCallId: string = "";
  getAdyenKeysCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      countryList: CountryList,
      selectedCountry: configJSON.defaultCountryValue,
      settlementCurrency: configJSON.defaultCurrencyValue,
      processingCurrency: configJSON.defaultCurrencyValue,
      processingAmount: 0,
      paymentMethods: [],
      orderItem: [],
      createdSession: {
        amount: {
          currency: "",
          value: 0,
        },
        countryCode: "",
        expiresAt: "",
        id: "",
        merchantAccount: "",
        reference: "",
        returnUrl: "",
        shopperLocale: "",
        mode: "",
        sessionData: "",
      },
      resultData: {
        resultCode: "",
        sessionData: "",
        sessionResult: "",
      },
      reference: configJSON.reference,
      isStatus: true,
      paymentUrl: "",
      isLoaderVisible: false,
      redirectResult: "",
      paymentType: "",
      pspReference: "",
      token: "",
      value: {
        amount: 0,
        currency: "",
      },
      baseUrl: "",
      processingCurrencyArr: ["EUR", "GBP", "USD"],
      isNote: false,
      merchantAccount:"",
      clientId:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.paymentContainer = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      switch (requestCallDataId) {
        case this.getAdyenKeysCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState(
                {
                  isLoaderVisible:false,
                  merchantAccount: responseSuccessWeb.merchant_account,
                  clientId:responseSuccessWeb.client_id
                }
              );
            },
            onFail: () => {
              this.setState({isLoaderVisible:false,})
              this.showAlert("Error", `${responseSuccessWeb?.errors
                ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                :configJSON.adyenKeysErrorMsg}`)
            },
          });
          break;
        }
        case this.getSessionCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState(
                { isLoaderVisible: false, createdSession: responseSuccessWeb },
                () => this.handleGetPaymentMethods(),
              );
            },
            onFail: () => {
              this.setState({ isLoaderVisible: false }, () =>
                this.showAlert(
                  `Error`,
                  `${responseSuccessWeb?.errors
                    ? Object.entries(responseSuccessWeb.errors[0])[0][1]
                    : configJSON.noDataErrorText
                  }`,
                ),
              );
            },
          });
          break;
        }
        case this.getPaymentCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState(
                {
                  paymentMethods: responseSuccessWeb?.paymentMethods,
                  isLoaderVisible: false,
                },
                () => this.initiatePayment(),
              );
            },
            onFail: () => {
              this.setState({ paymentMethods: [] }, () =>
                this.showAlert("Error", `${configJSON.paymentMethodsErrorMsg}`),
              );
            },
          });
          break;
        }
        case this.getConvertedValueCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState(
                {
                  processingAmount: responseSuccessWeb?.converted_value,
                },
                () => this.doCreatePaymentSession(),
              );
            },
            onFail: () => {
              this.setState({
                processingAmount: 0
              }, () => this.showAlert("Error", `${configJSON.convertApiErr}`))

            },
          });
          break;
        }
        case this.submitPaymentCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              if (responseSuccessWeb.resultCode === "Authorised") {
                this.handleWebhook(responseSuccessWeb.pspReference);
              } else if (responseSuccessWeb.resultCode === "RedirectShopper") {
                window.location.replace(`${responseSuccessWeb.action.url}`);
              } else {
                this.showAlert("Note", `${configJSON.paymentDeclinedText}`);
              }
            },
            onFail: () => {
              this.showAlert("Error", `${configJSON.alertMsg}`);
            },
          });
          break;
        }
        case this.getPaymentDetailsCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              if (responseSuccessWeb.resultCode === "Authorised") {
                this.setState(
                  {
                    pspReference: responseSuccessWeb.pspReference,
                    isLoaderVisible: false,
                  },
                  () => this.handleWebhook(responseSuccessWeb.pspReference),
                );
              } else if (responseSuccessWeb.resultCode === "Cancelled") {
                this.showAlert(
                  "Note",
                  `Payment is ${responseSuccessWeb.resultCode}`,
                );
                this.setState({ isLoaderVisible: false },()=>this.handleNavigateToOrders());
              } else {
                this.setState({ isLoaderVisible: false }, () => {
                  this.showAlert("Note", responseSuccessWeb.resultCode);
                });
              }
            },
            onFail: () => {
              this.showAlert("Error", `${responseErrorWeb?.error}`);
              this.setState({ isLoaderVisible: false });
            },
          });
          break;
        }
        case this.getWebhookDetailsCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              if (responseSuccessWeb.success) {
                this.setState({ isLoaderVisible: false, isNote: true }, () =>
                  this.showAlert("Success", `${configJSON.successNote}`),
                );
                setTimeout(() => {
                  this.handleNavigateToOrders();
                }, 5000);
              } else if (
                !responseSuccessWeb.success &&
                responseSuccessWeb.reason === "retry"
              ) {
                this.setState({ isLoaderVisible: true, isNote: false }, () => {
                  this.handleWebhook(this.state.pspReference);
                });
              }
            },
            onFail: () => {
              this.showAlert("Error", `${configJSON.paymentNotDoneText}`);
            },
          });
          break;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const itemData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );

      itemData?.data &&
        this.setState(
          { orderItem: itemData.data, reference: itemData.data.id },
          () => this.handleCurrencyExchange()
        );
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ baseUrl: window.location.origin, isNote: false });
    const localValue = await StorageProvider.get("value");
    const token = await StorageProvider.get("token");
    this.getAdyenKeys(token)

    localValue &&
      this.setState(
        {
          token:token,
          value: JSON.parse(localValue),
        }
      );

    const locationUrl = window.location;
    const newUrl = new URL(`${locationUrl}`);
    const params = new URLSearchParams(newUrl.search);
    const redirectResult = params.get("redirectResult");
    redirectResult &&
      this.setState({ redirectResult, isLoaderVisible: true },
        () => this.handlePaymentDetails(redirectResult)
      );
  }

  getAdyenKeys = (token:string) => {
    this.setState({isLoaderVisible:true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getAdyenKeysCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKeysEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelectDropdown = (value: string) => {
    const item = this.state.countryList.find(
      (countryItem) => countryItem.code === value,
    );
    item &&
      this.setState(
        {
          selectedCountry: item.code,
          settlementCurrency: item.currency,
          processingCurrency: item.currency,
        },
        () => this.handleCurrencyExchange(),
      );

    document.getElementById("dropin-container")?.remove();
    let element = document.createElement("div");
    element.setAttribute("id", "dropin-container");
    document
      .getElementsByClassName("payment-container")[0]
      ?.appendChild(element);
  };

  selectProcessingCurrency = (currency: string) => {
    document.getElementById("dropin-container")?.remove();
    let element = document.createElement("div");
    element.setAttribute("id", "dropin-container");
    document
      .getElementsByClassName("payment-container")[0]
      ?.appendChild(element);
    this.setState({ processingCurrency: currency }, () =>
      this.handleCurrencyExchange(),
    );
  };

  doCreatePaymentSession = () => {
    this.setState({ isLoaderVisible: true });
    const body = {
      value: this.state.processingAmount,
      currency: this.state.processingCurrency,
      countryCode: this.state.selectedCountry,
      merchant_account: this.state.merchantAccount,
      reference: this.state.reference,
      returnUrl: `${this.state.baseUrl}/${configJSON.adyenCheckoutText}`,
    };
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getSessionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSessionEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  initiatePayment = async () => {
    const checkout = await AdyenCheckout({
      environment: configJSON.environment,
      clientKey: this.state.clientId,
      session: {
        id: this.state.createdSession.id,
        sessionData: this.state.createdSession.sessionData,
      },
      onSubmit: (state) => {
        state.isValid && this.onSubmitPayment(state.data);
      },
      onAdditionalDetails: (state) => {
        state.details.redirectResult !== "" &&
          this.handlePaymentDetails(state.details.redirectResult);
      },
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          showPayButton: true,
          name: "Credit card or debit card",
          amount: {
            value:this.state.processingAmount * 100,
            currency:
              this.state.processingAmount === 0
                ? this.state.value.currency
                : this.state.processingCurrency,
          },
        },
      },

      amount: {
        value: this.state.processingAmount * 100,
        currency: this.state.processingCurrency,
      },
      setStatusAutomatically: false,
      paymentMethodsResponse: {
        paymentMethods: this.state.paymentMethods,
      },
      countryCode: this.state.selectedCountry,
    });
    checkout
      .create("dropin", {
        openFirstPaymentMethod: true,
      })
      ?.mount("#dropin-container");

    checkout.submitDetails({
      details: { redirectResult: this.state.redirectResult },
    });
  };

  handleGetPaymentMethods = () => {
    const body = {
      countryCode: this.state.selectedCountry,
      shopperLocale: configJSON.shopperLocale,
      currency: this.state.processingCurrency,
      value:
        this.state.processingAmount === 0
          ? this.state.value.amount * 100
          : this.state.processingAmount * 100,
      merchant_account: this.state.merchantAccount,
    };
    this.getPaymentMethods({
      contentType: configJSON.apiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.getPaymentMethodsEndPoint,
      body: body,
    });
  };

  getPaymentMethods = (data: IDataGetPayment) => {
    this.setState({ isLoaderVisible: true });
    const { contentType, method, endPoint, body } = data;

    const header = {
      "Content-Type": contentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getPaymentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method,
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSubmitPayment = (payData: IPayData) => {
    const type = payData.paymentMethod.type;
    this.setState({ paymentType: type });
    let body: ISubmitBody = {
      amount: {
        value: this.state.processingAmount * 100,
        currency: this.state.processingCurrency,
      },
      shopperLocale: configJSON.shopperLocale,
      country_code: this.state.selectedCountry,
      type: type,
      reference: this.state.reference,
      shopper_reference: configJSON.shopperReference,
      returnUrl: `${this.state.baseUrl}/${configJSON.adyenCheckoutText}`,
      merchant_account: this.state.merchantAccount,
    };
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.submitPaymentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitPaymentEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    if (type === "scheme") {
      body = {
        ...body,
        encryptedCardNumber: payData.paymentMethod.encryptedCardNumber,
        encryptedExpiryMonth: payData.paymentMethod.encryptedExpiryMonth,
        encryptedExpiryYear: payData.paymentMethod.encryptedExpiryYear,
        encryptedSecurityCode: payData.paymentMethod.encryptedSecurityCode,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    } else {
      body = {
        amount: {
          value:this.state.processingAmount * 100,
          currency: this.state.processingCurrency,
        },
        shopperLocale: configJSON.shopperLocale,
        country_code: this.state.selectedCountry,
        type: type,
        reference: this.state.reference,
        shopper_reference: configJSON.shopperReference,
        merchant_account: this.state.merchantAccount,
        returnUrl: `${this.state.baseUrl}/${configJSON.adyenCheckoutText}`,
        shopperEmail: configJSON.email,
        telephoneNumber: configJSON.telephoneNumber,
        shopperName: {
          firstName: configJSON.firstName,
          gender: configJSON.gender,
          lastName: configJSON.lastName,
        },
        billingAddress: {
          city: configJSON.city,
          country: this.state.selectedCountry,
          houseNumberOrName: configJSON.houseNumberOrNameText,
          postalCode: configJSON.postalCode,
          street: configJSON.street,
        },
        deliveryAddress: {
          city: configJSON.city,
          country: this.state.selectedCountry,
          houseNumberOrName: configJSON.houseNumberOrNameText,
          postalCode: configJSON.postalCode,
          street: configJSON.street,
          stateOrProvince: configJSON.stateText,
        },
        lineItems: [
          {
            quantity: "1",
            amountExcludingTax: "",
            taxPercentage: "",
            description: configJSON.itemNameValue,
            id: "Item #1",
            taxAmount: "",
            amountIncludingTax: "",
            productUrl: "URL_TO_PURCHASED_ITEM",
            imageUrl: "URL_TO_PICTURE_OF_PURCHASED_ITEM",
          },
        ],
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body),
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleWebhook = (pspReference: string) => {
    this.setState({ isLoaderVisible: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { pspReference };
    this.getWebhookDetailsCallId = requestMessage.messageId;
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getWebhookApi}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    setTimeout(
      () => {
        runEngine.sendMessage(requestMessage.id, requestMessage);
      },
      this.state.paymentType === "scheme" ? 1000 : 5000,
    );
  };

  handlePaymentDetails = (redirectResult: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const body = {
      details: {
        redirectResult,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getPaymentDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentDetails}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateToOrders = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdyenIntegration",
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
    await StorageProvider.remove("value");
  };

  handleCurrencyExchange = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getConvertedValueCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    const base_currency = this.state.settlementCurrency;
    const target_currency = this.state.processingCurrency;
    const value = this.state.value?.amount?.toString();
    const params = { base_currency, target_currency, value };
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getConvertedValueEndPoint}?${urlParams}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAmount = () => {
    if (this.state.processingAmount == 0) {
      return new Intl.NumberFormat(`${configJSON.shopperLocale}`, {
        style: "currency",
        currency: this.state.processingCurrency,
      }).format(this.state.value.amount);
    } else {
      return new Intl.NumberFormat(`${configJSON.shopperLocale}`, {
        style: "currency",
        currency: this.state.processingCurrency,
      }).format(this.state.processingAmount);
    }
  };
  // Customizable Area End
}
