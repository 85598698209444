import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Dialog, 
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  CircularProgress,
  TablePagination,
  TextField,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ShippingSpeed from "./EasypostComponents/ShippingSpeed/ShippingSpeed.web";
import EasypostAnalytics from "./EasypostComponents/EasypostAnalytics/EasypostAnalytics.web";
import {
  MyOrder,
  ORDER_STATUS,
  TrackingHistory
} from "./types/types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#90CAF9",
      contrastText: "#000000",
    },
  },
});
// Customizable Area End

import EasypostintegrationController, {
  Props,
  configJSON,
} from "./EasypostintegrationController";

export default class Easypostintegration extends EasypostintegrationController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} style={{ display: "flex", gap: "1rem" }}>
          <TextField
            id="outlined-staging"
            label="Staging Key"
            value={this.state.staging}
            onChange={this.handleStagingInputChange}
            margin="normal"
            variant="outlined"
            data-test-id="staging-key-input"
          />
          <TextField
            id="outlined-prod"
            label="Production Key"
            value={this.state.prod}
            onChange={this.handleProdInputChange}
            margin="normal"
            variant="outlined"
            data-test-id="prod-key-input"
          />
          <Button data-test-id="save-keys-button" variant="contained" onClick={() => this.requestSetKeys()}>
            Save Keys
          </Button>
        </Container>
        {(this.state.staging && this.state.prod) && (
          <Container maxWidth={"lg"}>
            <Typography variant="h4">{this.state.isMerchant ? "Merchant" : "Shopper"}</Typography>
            {!this.state.isMerchant && (
              <Paper style={{ margin: "2rem"}}>
                <Typography variant="h6">Place Order</Typography>
                <ShippingSpeed orderPlaced={() => this.handleOrdersData(0)} />
              </Paper>
            )}
            <Paper style={{ margin: "2rem"}}>
              <Typography variant="h6">{this.state.isMerchant ? "Manage Order" : "My Orders"}</Typography>
              <Box>
                <p>Selected Order ID:</p>
                <p>{this.state.selectedOrder?.id}</p>
              </Box>
              <TableContainer component={Paper}>
                <Button variant="contained" onClick={() => this.handleOrdersData(0)} data-test-id="refresh-table-button">
                  Refresh Table Contents
                </Button>
                {this.state.isTableLoading ? <CircularProgress /> : (
                  <Table
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order number</TableCell>
                        <TableCell> Status</TableCell>
                        {this.state.isMerchant && (
                          <TableCell> Customer</TableCell>
                        )}
                        <TableCell> Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.orders &&
                        this.state.orders.map((item: MyOrder, index) => {
                          return (
                            <TableRow
                              data-test-id={"order-row-" + index}
                              key={`item${index}`}
                              onClick={() => this.handleSelectedOrderChange(item)}>
                              <TableCell>
                                {item.id}
                              </TableCell>
                              <TableCell>
                                {item.attributes.easypost_order_status}
                              </TableCell>
                              {this.state.isMerchant && (
                                <TableCell>
                                  {item.attributes.customer.data.attributes.email}
                                </TableCell>
                              )}
                              <TableCell>
                                {this.state.isMerchant ? (
                                  <>
                                    {(item.attributes.easypost_order_status ===  ORDER_STATUS.PLACED && !!item.attributes.easypost_shipment?.rate_id) && (
                                      <Button
                                      variant="contained"
                                      data-test-id={"order-ship-" + index}
                                      onClick={() => this.editOrder(item)} 
                                    >
                                      Ship Order
                                    </Button>
                                    )}
                                    {[
                                      ORDER_STATUS.SHIPPED, 
                                      ORDER_STATUS.PRE_TRANSIT, 
                                      ORDER_STATUS.IN_TRANSIT, 
                                      ORDER_STATUS.OUT_FOR_DELIVERY, 
                                      ORDER_STATUS.DELIVERED, 
                                      ORDER_STATUS.RETURN_PRE_TRANSIT,
                                      ORDER_STATUS.RETURN_IN_TRANSIT,
                                      ORDER_STATUS.RETURN_OUT_FOR_DELIVERY,
                                      ORDER_STATUS.RETURN_DELIVERED,
                                      ORDER_STATUS.RETURN_SHIPPED,
                                    ].includes(item.attributes.easypost_order_status as ORDER_STATUS) && (
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        data-test-id={"order-track-" + index}
                                        onClick={() => this.trackOrder(item)}
                                      >
                                        Track Order
                                      </Button>
                                    )}
                                    {(item.attributes.easypost_order_status === ORDER_STATUS.CANCELLED || item.attributes.easypost_order_status === ORDER_STATUS.RETURN_DELIVERED) && (
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                      >
                                        Refund Order
                                      </Button>
                                    )}
                                    {(item.attributes.easypost_order_status ===  ORDER_STATUS.SHIPMENT_IN_PROGRESS) && (
                                      <>
                                        {item.attributes?.easypost_shipment?.label_url && (
                                          <Button
                                          target="_blank"
                                          variant="outlined" 
                                          href={item.attributes?.easypost_shipment?.label_url}
                                          >
                                            Print Shipping Label
                                          </Button>
                                        )}
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          data-test-id={"order-pickup-" + index}
                                          onClick={() => this.createPickup(item)} 
                                          disabled={item.attributes.easypost_shipment?.is_pickup_created }
                                        >
                                          {item.attributes.easypost_shipment?.is_pickup_created ? "Pickup Created" : "Create Pickup"}
                                        </Button>
                                      </>
                                    )}
                                  </>
                                ): (
                                  <>
                                    {[
                                      ORDER_STATUS.PLACED, 
                                      ORDER_STATUS.SHIPMENT_IN_PROGRESS, 
                                      ORDER_STATUS.PRE_TRANSIT, 
                                    ].includes(item.attributes.easypost_order_status as ORDER_STATUS)
                                    && (
                                      <Button
                                        size="small" 
                                        color="primary" 
                                        variant="contained" 
                                        data-test-id={"order-cancel-button-" + index}
                                        onClick={() => this.cancelOrder(item)}
                                      >
                                        Cancel
                                      </Button>
                                    )}
                                    {(item.attributes.easypost_order_status ===  ORDER_STATUS.DELIVERED) && (
                                      <Button
                                        color="secondary" 
                                        variant="contained" 
                                        data-test-id={"return-button-" + index}
                                        onClick={() => this.returnOrder(item)}
                                      >
                                        Return
                                      </Button>
                                    )}
                                    
                                    {(item.attributes.easypost_order_status === ORDER_STATUS.RETURN_IN_PROGRESS) && ( 
                                      <>
                                        {item.attributes?.easypost_return_shipment?.label_url && (
                                          <Button
                                          target="_blank"
                                          variant="outlined"
                                          href={item.attributes?.easypost_return_shipment?.label_url}
                                          >
                                            Print Shipping Label
                                          </Button>
                                        )}
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          onClick={() => this.createPickup(item)} 
                                          data-test-id={"createpickup-button-" + index}
                                          disabled={!!item.attributes.easypost_return_shipment?.is_pickup_created}
                                        >
                                          {item.attributes.easypost_return_shipment?.is_pickup_created ? "Pickup Created" : "Create Pickup"}
                                        </Button>
                                      </>
                                    )}
                                    {[ORDER_STATUS.UNKNOWN, ORDER_STATUS.PRE_TRANSIT, ORDER_STATUS.IN_TRANSIT, ORDER_STATUS.OUT_FOR_DELIVERY].includes(item.attributes.easypost_order_status as ORDER_STATUS) && (
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        data-test-id={"track-button-" + index}
                                        onClick={() => this.trackOrder(item)}
                                      >
                                        Track Order
                                      </Button>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}
                {this.state.orderMeta && (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10]}
                    count={this.state.orderMeta.total_count}
                    rowsPerPage={10}
                    page={this.state.page}
                    onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => this.handleOrdersData(page)}
                    data-test-id="table-controls"
                  />
                )}
              </TableContainer>
            </Paper>
            {this.state.isMerchant && (
              <Paper>
                <EasypostAnalytics />
              </Paper>
            )}
          </Container>
        )}
        
        <Dialog
          maxWidth={"sm"}
          open={this.state.isVisibleCancelOrderModal}
          onClose={this.resetCancelOrderModal}>
          <DialogContent>
            <Typography variant="h6">
              Cancel Order
            </Typography>
            <Typography variant="body1">
              Are you sure you want to cancel order?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              data-test-id="close-cancel-order-button"
              onClick={this.resetCancelOrderModal}>
              {configJSON.cancelBtnLabel}
            </Button>
            <Button
              color="primary"
              variant="contained"
              data-test-id="cancel-order-button"
              onClick={this.requestCancelOrder}>
              {configJSON.buyShipmentBtnLabel}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth={"sm"}
          open={this.state.isVisibleReturnOrderModal}
          onClose={this.resetReturnOrderModal}>
          <DialogContent>
            <Typography variant="h6">
              Return Order
            </Typography>
            <Typography variant="body1">
              Are you sure you want to return order?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              data-test-id="close-return-order-button"
              onClick={this.resetReturnOrderModal}>
              {configJSON.cancelBtnLabel}
            </Button>
            <Button
              color="primary"
              variant="contained"
              data-test-id="return-order-button"
              onClick={this.requestReturnOrder}>
              {configJSON.buyShipmentBtnLabel}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth={"sm"}
          open={this.state.isVisibleEditOrderModal}
          onClose={this.resetEditOrderModal}>
          <DialogContent>
            <Typography variant="h6">
              Edit Order
            </Typography>
            <Box>
              <Typography variant="body1">
                Delivery Speed: {this.state.selectedOrder?.attributes.easypost_shipment?.rate?.est_delivery_days} day(s)
              </Typography>
              <Typography variant="body1">
                Delivery Fees Paid: ${this.state.selectedOrder?.attributes.easypost_shipment?.rate?.rate}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              data-test-id="close-ship-order"
              onClick={this.resetEditOrderModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              data-test-id="ship-order"
              onClick={this.buyShipment}>
              Ship Order
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.isVisibleTrackOrderModal}>
          <DialogContent>
            <Typography variant="h6">
              Track Order
            </Typography>
            <Box>
              <Table
                size="small"
                aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date as of</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Location (State, Ciry)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.trackingHistory.map((item: TrackingHistory, index) => {
                      return (
                        <TableRow
                          data-test-id="tableRowId"
                          key={`item${index}`}
                        >
                          <TableCell>
                            {item.datetime}
                          </TableCell>
                          <TableCell>
                            {item.status}
                          </TableCell>
                          <TableCell>
                            {item.tracking_location?.state}, {item.tracking_location?.city}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              data-test-id="cancel-tracking-button"
              onClick={() => this.resetTrackOrderModal()}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.isVisiblePickupToast}
          onClose={this.handlePickupToastClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Shipment will be picked up shortly.</span>}
          data-test-id="close-pickup-toast-button"
          onClick={() => this.handlePickupToastClose}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handlePickupToastClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.isVisibleShipToast}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          data-test-id="close-print-toast-button"
          onClick={() => this.handleShipToastClose()}
          message={<span id="message-id">Print the Shipping Label.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleShipToastClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    gap: "3rem",
  }
};
// Customizable Area End
