import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { ChangeEvent } from "react";
import storage from "framework/src/StorageProvider";
import { handleResponseMessage } from "./helpers/handle-response-message";
import { IHistoryCartOrders, Order } from "./types/Types";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IHistoryCartOrders;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // cartOrderId: string;
  cartCatalogueId: string;
  cartQuantity: string;
  cartTaxable: boolean;
  cartTaxableValue: string;
  cartToken: string;
  cartOrderList: Array<Order>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ccavenueintegration2CartItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createApiOrderItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // cartOrderId: "",
      cartCatalogueId: "",
      cartQuantity: "",
      cartTaxable: false,
      cartTaxableValue: "",
      cartToken: "",
      cartOrderList: [],
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    // Customizable Area Start
    const token = await storage.get("token");
    this.setState({ cartToken: token });
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );

      if (requestCallDataId === this.createApiOrderItemCallId) {
        handleResponseMessage({
          responseJson: responseSuccessWeb,
          errorJson: responseErrorWeb,
          onSuccess: () => {
            this.createOrderItemApi(responseSuccessWeb);
          },
          onFail: () => {
            this.showAlert(`Error`, "Create order failed !");
          },
        });
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  createOrderItemApi = (responseJson: { data: S["cartOrderList"] }) => {
    responseJson &&
      this.props.navigation.navigate("Ccavenueintegration2CartOrders");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("Ccavenueintegration2CartOrders");
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  createOrderItem = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const order_items = {
      catalogue_id: parseInt(this.state.cartCatalogueId),
      quantity: parseInt(this.state.cartQuantity),
      taxable: this.state.cartTaxable,
      taxable_value: parseInt(this.state.cartTaxableValue) || 0,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.createApiOrderItemCallId = requestMessage.messageId;
    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.props.navigation.navigate("Ccavenueintegration2CartOrders");
    return true;
  };

  handleChangeInputCartOrders = (event: ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value) || 0;
    let name = event.target.name;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  handleToggleTaxable = () =>
    this.setState({ cartTaxable: !this.state.cartTaxable });

  // Customizable Area End
}
