import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  TextField,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { 
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeriesPoint,
  VerticalBarSeries,
} from 'react-vis';

// Customizable Area End

import EasypostAnalyticsController, {
  Props,
} from "./EasypostAnalyticsController";

export default class EasypostAnalytics extends EasypostAnalyticsController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container style={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        marginTop: "2rem",
        padding: "2rem"
      }}>
        <Typography variant="h4">
          Analytics
        </Typography>
        <Container maxWidth={"lg"} style={{
          display: "flex",
          gap: "2rem",
          alignContent: "center",
          flexDirection: "column",
        }}>
          <Box style={{
            display: "flex",
            gap: "2rem",
            alignContent: "center",
          }}>
            <TextField
              id="date"
              label="From"
              type="date"
              value={this.state.from}
              variant="outlined"
              onChange={this.handleFromInputChange}
              data-test-id="from-date-input"
            />
            <TextField
              id="date"
              label="To"
              type="date"
              value={this.state.to}
              variant="outlined"
              onChange={this.handleToInputChange}
              data-test-id="to-date-input"
            />
            <Button
              color="primary"
              variant="contained"
              data-test-id="analytics-refresh-button"
              onClick={() => this.getAnalyticsOrdersRequest()}>
              Update
            </Button>
          </Box>
          {this.state.analyticsResponse && (
            <Box style={{
              display: "flex",
              gap: "2rem",
              alignContent: "center",
              marginBottom: '4rem'
            }}>
              <Box style={{
                display: "flex",
                gap: "2rem",
                alignContent: "center",
                flexDirection: "column",
              }}>
                <Box>Revenue ${this.state.analyticsResponse.revenue}</Box>
                <Box>Orders {this.state.analyticsResponse.orders}</Box>
                <Box>Shipment Labels {this.state.analyticsResponse.shipment_labels}</Box>
                <Box>Pickups {this.state.analyticsResponse.pickups}</Box>
                <Box>Deliveries {this.state.analyticsResponse.deliveries}</Box>
                <Box>Returned {this.state.analyticsResponse.returned}</Box>
              </Box>
              <Box>
              <Box style={{margin: '1rem'}}>
                <XYPlot
                  xType="ordinal"
                  width={900}
                  height={500}
                  style={{overflow: 'visible', padding: '1rem'}}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <YAxis title="Total Orders" />
                  <VerticalBarSeries barWidth={1} data={this.state.analyticsOrders.map((item: VerticalBarSeriesPoint) => ({
                     x: item.date,
                     y: item.orders_count
                  }))} style={{stroke: '#fff'}} />
                  <XAxis 
                    width={200} 
                    height={200}  
                    title="Date" 
                    tickLabelAngle={-90}
                  />
                </XYPlot>
              </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
