import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import storage from "framework/src/StorageProvider";
import {
  MyOrder,
  OrderMeta,
  ShippingLabel,
  TrackingHistory
} from "./types/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accessToken: string;
  orders: MyOrder[];
  orderMeta: OrderMeta | null;
  selectedOrderId: string;
  selectedOrder: MyOrder | null;
  isLoadingBuyShipment: boolean;
  buyShipmentRequestError: string;
  isVisibleReturnOrderModal: boolean;
  isVisibleCancelOrderModal: boolean;
  isVisibleEditOrderModal: boolean;
  isVisiblePickupToast: boolean;
  isVisibleShipToast: boolean;
  isVisibleRefundToast: boolean;
  isVisibleTrackOrderModal: boolean;
  isTableLoading: boolean;
  isMerchant: boolean;
  shippingLabel: ShippingLabel | null;
  trackingHistory: TrackingHistory[];
  page: number;
  staging: string;
  prod: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EasypostintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ordersGetApiCallId: string = "";
  postBuyShipmentApiCallId: string = "";
  postReturnOrderApiCallId: string = "";
  postCreatePickupApiCallId: string = "";
  putUpdateOrderApiCallId: string = "";
  postTrackOrderApiCallId: string = "";
  postLoginUserApiCallId: string = "";
  postKeysApiCallId: string = "";
  getKeysApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orders: [],
      accessToken: "",
      selectedOrderId: "",
      selectedOrder: null,
      isLoadingBuyShipment: false,
      buyShipmentRequestError: "",
      isVisibleReturnOrderModal: false,
      isVisibleCancelOrderModal: false,
      isVisiblePickupToast: false,
      isVisibleEditOrderModal: false,
      isVisibleShipToast: false,
      isMerchant: false,
      shippingLabel: null,
      isVisibleTrackOrderModal: false,
      isTableLoading: false,
      trackingHistory: [],
      orderMeta: null,
      isVisibleRefundToast: false,
      page: 0,
      staging: "",
      prod: "",
      // Customizable Area End
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  resetTableLoading () {
    this.setState({isTableLoading: false})
  }

  async setTokenAndAccount(accessToken:string, account: string) {
    this.setState({ accessToken }, () => {
      this.handleOrdersData()
      this.setState({ isMerchant:  (JSON.parse(account)).user_type == "merchant"}) 
      this.requestGetKeys()
    });
  }

  async componentDidMount() {
    const accessToken = await storage.get("token");
    const account = await storage.get("account");
    this.setTokenAndAccount(accessToken, account)
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );

    switch (apiRequestCallId) {
      case this.postTrackOrderApiCallId:
          this.setState({trackingHistory: responseJson})
          break;
      case this.putUpdateOrderApiCallId:
        this.setState({isVisibleCancelOrderModal: false})
        this.handleOrdersData()
        break;
      case this.ordersGetApiCallId:
        if (responseJson.data) {
          this.setState({ orders: responseJson.data });
          this.setState({orderMeta: responseJson.meta})
        }
        this.setState({isTableLoading: false})
        break;
      case this.postReturnOrderApiCallId: 
        this.setState({ isVisibleReturnOrderModal: false })
        this.handleOrdersData()
        break;
      case this.postBuyShipmentApiCallId:
        this.setState({ isVisibleShipToast: true })
        this.handleOrdersData()
        this.setState({ isVisibleEditOrderModal: false })
        break;
      case this.postCreatePickupApiCallId:
        this.handleOrdersData()
        this.setState({ isVisiblePickupToast: true });
        break;
      case this.postLoginUserApiCallId: 
        this.setState({ isMerchant:  responseJson.meta.account.user_type == "merchant"}) 
        break;
      case this.getKeysApiCallId: 
        if (responseJson.easypost_user_info) {
          this.setState({prod: responseJson.easypost_user_info.easypost_production_api_key, staging: responseJson.easypost_user_info.easypost_test_api_key })
        }
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleOrdersData = (page: number = 0) => {
    if (this.state.isTableLoading) {
      this.resetTableLoading()
      return
    }
    this.setState({ page })
    this.setState({isTableLoading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.ordersGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShippingCartOrderAPiEndPoint + (page + 1),
    );
    const header = {
      "Content-Type": configJSON.getOrdersApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePickupToastClose = () => {
    this.setState({ isVisiblePickupToast: false });
  }

  handleShipToastClose = () => {
    this.setState({ isVisibleShipToast: false });
  }

  handleSelectedOrderChange = (order: MyOrder) => {
    this.setState({ selectedOrder: order});
  }

  buyShipment = async () => {
    this.setState({ isLoadingBuyShipment: true });
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postBuyShipmentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    if (this.state.selectedOrder) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postBuyShipmentEndPoint.replace('{ORDER_ID}', this.state.selectedOrder.id),
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  requestReturnOrder = async () => {
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postReturnOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    if (this.state.selectedOrder) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postReturnOrderEndPoint.replace('{ORDER_ID}', this.state.selectedOrder.id),
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createPickup = async (order: MyOrder) => {
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postCreatePickupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreatePickupEndPoint.replace('{ORDER_ID}', order.id) + (this.state.isMerchant ? '' : '?return_order=true'),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  returnOrder = (order: MyOrder) => {
    this.setState({ selectedOrder: order });
    this.setState({ isVisibleReturnOrderModal: true });
  }

  resetReturnOrderModal = () => {
    this.setState({ isVisibleReturnOrderModal: false });
  }

  trackOrder = (order: MyOrder) => {
    this.setState({selectedOrder: order, isVisibleTrackOrderModal: true, trackingHistory: []})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postTrackOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postTrackOrderEndPoint.replace('{ORDER_ID}', order.id),
    );
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleStagingInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({staging: event.target.value as string})
  }

  handleProdInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({prod: event.target.value as string})
  }

  resetTrackOrderModal = () => {
    this.setState({ isVisibleTrackOrderModal: false });
  }

  cancelOrder = (order: MyOrder) => {
    this.setState({ selectedOrder: order })
    this.setState({ isVisibleCancelOrderModal: true });
  }

  requestCancelOrder = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.putUpdateOrderApiCallId = requestMessage.messageId;

    if (this.state.selectedOrder) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCancelOrderEndPoint.replace('{ORDER_ID}', this.state.selectedOrder.id),
      );
    }
    
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  requestSetKeys = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.postKeysApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postKeysEndPoint
    );
    
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        production_key: this.state.prod,
        test_key: this.state.staging
      }),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  requestGetKeys = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getKeysApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKeysEndPoint
    );
    
    const header = {
      "Content-Type": configJSON.postPutApiContentType,
      "token": this.state.accessToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resetCancelOrderModal = () => {
    this.setState({ isVisibleCancelOrderModal: false });
  }

  editOrder = (order: MyOrder) => {
    this.setState({ selectedOrder: order });
    this.setState({ isVisibleEditOrderModal: true });
  }

  resetEditOrderModal = () => {
    this.setState({ isVisibleEditOrderModal: false });
  }
  // Customizable Area End
}
