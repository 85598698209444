import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider";
import { handleResponseMessage } from "./helpers/handle-response-message";
import { IHistoryCartOrders, Item, Order } from "./types/Types";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IHistoryCartOrders
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cartOrderId: string;
  cartCatalogueId: string;
  cartQuantity: string;
  cartTaxable: boolean;
  cartTaxableValue: string;
  cartToken: string;
  cartOrderList: Array<Order>;
  cartOrderItems: Array<Item>,
  isVisible: boolean;
  cartId: string;
  cartName: string;
  cartDescription: string;
  cartPrice: number;
  cartCurrency: string;
  cartCategoryId: number;
  cartCreatedAt: string;
  cartUpdatedAt: string;
  cartAccountId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ccavenueintegration2CartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiOrdersCallId: string = "";
  showApiOrderCallId: string = "";
  deleteApiOrderItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cartOrderId: "",
      cartCatalogueId: "",
      cartQuantity: "",
      cartTaxable: false,
      cartTaxableValue: "",
      cartToken: "",
      cartOrderList: [],
      cartOrderItems: [],
      isVisible: false,
      cartId: "",
      cartName: "",
      cartDescription: "",
      cartPrice: 0,
      cartCurrency: "",
      cartCategoryId: 0,
      cartCreatedAt: "",
      cartUpdatedAt: "",
      cartAccountId: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    // Customizable Area Start
    const token = await storage.get("token");
    this.setState({ cartToken: token });
    this.getOrders(token)
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestCallDataId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );

      const responseSuccessWeb = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      const responseErrorWeb = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      switch (requestCallDataId) {
        case this.getApiOrdersCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.setState({ cartOrderList: responseSuccessWeb.data })
            },
            onFail: () => {
              this.setState({ cartOrderList: [] });
              this.showAlert(`Error`, "No data !");
            },
          });
          break;
        }
        case this.showApiOrderCallId: {
          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.showOrderApi(responseSuccessWeb.attributes?.order_items.data,this.state.cartOrderId);
            },
            onFail: () => {
              this.showAlert(`Error`, "No orders !");
            },
          });
          break;
        }
        case this.deleteApiOrderItemCallId: {

          handleResponseMessage({
            responseJson: responseSuccessWeb,
            errorJson: responseErrorWeb,
            onSuccess: () => {
              this.getOrders(this.state.cartToken);
              this.showAlert("Note", responseSuccessWeb.message)
            },
            onFail: () => {
              this.showAlert(`Error`, "delete order failed !");
            },
          });
          break;
        }
      }

    }
    // Customizable Area End
  };

  // Customizable Area Start
  showOrderApi = (responseJson: Item[],cartOrderId : string) => {
    responseJson && this.setState({
      cartOrderItems: responseJson,
      isVisible: !this.state.isVisible,
      cartOrderId
    });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("Ccavenueintegration2CartOrderItem");
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(message);
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    const params = { filter_by: "scheduled" };

    this.getApiOrdersCallId = requestMessage.messageId;
    const urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteOrderItem = (orderId: string, orderItemId: number) => {
    this.setState({ isVisible: !this.state.isVisible, cartOrderId: orderId });
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.cartToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.deleteApiOrderItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}/${orderId}/order_items/${orderItemId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckout = (checkItem: Order) => {
    this.setState({ cartOrderId: checkItem.id });
    this.props.history.push("Ccavenueintegration2Checkout", {
      data: {
        item: checkItem,
        token: this.state.cartToken
      }
    })

  }
  // Customizable Area End
}
