import React from "react";
import {

  // Customizable Area Start
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Phonepeintegration1CheckoutController, {
  configJSON,
  Props,
} from "./Phonepeintegration1CheckoutController";

export default class Phonepeintegration1Checkout extends Phonepeintegration1CheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <Container maxWidth="md">
        {
          
          <Box>
            <Box style={webStyle.box}>
              <h3 >
                {configJSON.shopperSectionText}
              </h3>
            </Box>
            <Paper style={webStyle.paper}>
              <TableContainer style={webStyle.tableContainer}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow >
                      <TableCell style={webStyle.tableHeadings}>{configJSON.serialNoText}</TableCell>
                      <TableCell style={webStyle.tableHeadings}>{configJSON.orderAmountText}</TableCell>
                      <TableCell style={webStyle.tableHeadings}>{configJSON.quantityText}
                      </TableCell>
                      <TableCell align="right" style={webStyle.tableHeadings}>Checkout
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow data-test-id="orderList">
                      <TableCell scope="row">1</TableCell>
                      <TableCell>
                        {configJSON.orderAmount}
                      </TableCell>
                      <TableCell >
                        {configJSON.NaText}
                      </TableCell>
                      <TableCell align="right">
                      
                        <Button
                          data-test-id="checkoutBtn"
                          variant="contained"
                          color="primary"
                          onClick={
                            () => this.handleCheckout()
                          }
                        >
                          
                          {configJSON.checkoutText}
                        </Button>

                      </TableCell>

                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Box style={webStyle.box}>
              <h3>
                {configJSON.merchantSctionText}
              </h3>
            </Box>
            <Box style={webStyle.merchantBox}>
              <Button
                variant="contained" color="primary"
                data-test-id={`showQrBtn`}
              onClick={this.openQrPage}
              >
                {configJSON.showQrText}
              </Button>
            </Box>
          </Box>
        }
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  box: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    width: "100%",
    overflow: "hidden",
  },
  cartHeading: { color: "rgb(98, 0, 238)" },
  tableHeadings: {
    color: "rgb(98, 0, 238)",
    textTransform: "capitalize" as "capitalize"
  },
  warningColor: {
    color: "red",
    padding: 0,
    margin: 0
  },
  tableContainer: {
    maxHeight: 440,
  },
  merchantBox: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "center",

  }
};
// Customizable Area End
