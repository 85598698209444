Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.chargeApiEndPoint = "bx_block_tappaymentsintegration/tappayment_checkout/charges";
exports.webhookApiEndPoint = "bx_block_tappaymentsintegration/tappayment_checkout/webhook?charge_id=";
exports.postApiMethod = "POST";
exports.getApiMethod = "GET";
exports.apiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "tappaymentsintegration";
exports.labelBodyText = "tappaymentsintegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.labelAmount = "Amount"
exports.labelRefIdTransaction = "Reference Transaction ID"
exports.labelRefIdOrder = "Reference Order ID"
exports.amountLimit = "Amount must be between 1 to 10,000.00";
exports.requireRefIdTransaction = "Reference Transaction ID is required";
exports.requireRefIdOrder = "Reference Order ID is required";
exports.labelPayTap = "Pay with Tap";
exports.labelPaySuccess = "Payment Successful";
exports.labelPayFailed = "Payment Failed";
exports.labelCheckingStatus = "Rechecking payment status...";
exports.labelRedirecting = "Redirecting, please wait.";
exports.labelClose = "Close";
exports.menuAED = "AED";
exports.tappayPage = "TappaymentsIntegration"
// Customizable Area End