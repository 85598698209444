Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.postApiMethod = "POST";
exports.getApiMethod = "GET";
exports.postCreateShipmentAPiEndPoint = "bx_block_easypost/shipment";
exports.getAnalyticsAPiEndPoint = "bx_block_easypost/orders/analytics?";
exports.getAnalyticsOrdersAPiEndPoint = "bx_block_easypost/orders/orders_chart?";
exports.postCreateShipmentApiContentType = "application/json";
exports.bankAccount = "Bank Account";
exports.creditCard = "Credit Card";
// Customizable Area End