import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import { TrackOrderType, IUpdateHistory } from "../src/types/types";
import { trackOrderData } from "../__mocks__/mocks";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: IUpdateHistory;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderId: number;
  trackingData: TrackOrderType;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TrackOrderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiOrderTrackingById: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderId: 0,
      trackingData: trackOrderData,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const orderId = this.props.history.location?.state?.data.customerData?.id;
    this.setState({ orderId }, () => {
      this.trackOrderByIdMethod();
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage),
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
      );
      if (errorResponse || responseJson.error || responseJson.errors) {
        (errorResponse || responseJson.errors) &&
          this.showAlert("", responseJson.errors);
        responseJson.error &&
          this.parseApiCatchErrorResponse(responseJson.error);
      } else if (apiRequestCallId === this.apiOrderTrackingById) {
        let { data } = responseJson;
        if (
          responseJson.status === 404 ||
          data.status_code === 404 ||
          data.message
        ) {
          this.showAlert("", configJSON.textOrderStatusNotFound);
        } else {
          this.setState({ trackingData: responseJson || trackOrderData });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  trackOrderByIdMethod = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.apiOrderTrackingById = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.trackOrderEndPoint}${this.state.orderId}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGoBackToOrders = () => {
    this.props.navigation.navigate("Shiprocketintegrate2");
  };

  // Customizable Area End
}
