import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  apiKeyValue: string
  sidValue: string
  messageValue: string
  phoneNumberValue: string
  senderIdValue: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Kaleyra2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  meassgeApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      apiKeyValue: "",
      sidValue: "",
      messageValue: "",
      phoneNumberValue: "",
      senderIdValue: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value,
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (apiRequestCallId === this.meassgeApiId) {

          if (responseJson.message) {
            this.showAlert("Error", responseJson.message || "An error occurred.");
          } else {
            this.setState({
              apiKeyValue: "",
              sidValue: "",
              messageValue: "",
              phoneNumberValue: "",
              senderIdValue: ""
            });
            this.showAlert("Success", configJSON.successSubmit);

          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  submitMessage = (): void => {
    const {
      apiKeyValue,
      sidValue,
      messageValue,
      phoneNumberValue,
      senderIdValue
    } = this.state;

    if (apiKeyValue.length === 0) {
      this.showAlert("Error", configJSON.apyKeySubmit);
    } else if (sidValue.length === 0) {
      this.showAlert("Error", configJSON.sidSubmit);
    } else if (messageValue.length === 0) {
      this.showAlert("Error", configJSON.messageSubmit);
    } else if (phoneNumberValue.length === 0) {
      this.showAlert("Error", configJSON.phoneNumberSubmit);
    } else if (senderIdValue.length === 0) {
      this.showAlert("Error", configJSON.senderIdSubmit);
    } else {
      this.createMessage();
    }
  };

  validatePhoneNumber(phoneNumber: string): boolean {
    const pattern = /^\+\d{1,3}\d{10}$/;
    return pattern.test(phoneNumber);
  }
  // Customizable Area End
  // Customizable Area End

  // Customizable Area Start
  createMessageApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    const header = {
      "Content-Type": contentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  createMessage = async () => {
    const { phoneNumberValue, senderIdValue, messageValue, apiKeyValue, sidValue } = this.state;

    if (!this.validatePhoneNumber(phoneNumberValue)) {
      this.showAlert("Error", configJSON.phoneNumberSubmit);
      return;
    }

    const body = {
      to: phoneNumberValue,
      sender_id: senderIdValue,
      message: messageValue,
      api_key: apiKeyValue,
      SID: sidValue
    };

    this.meassgeApiId = await this.createMessageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.sendMessgaeAPiMethod,
      endPoint: configJSON.sendMessageAPiEndPoint,
      body: body
    });
  };

  setApiKey = (text: string) => {
    this.setState({ apiKeyValue: text });
  };

  setSid = (text: string) => {
    this.setState({ sidValue: text });
  };

  setMessage = (text: string) => {
    this.setState({ messageValue: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumberValue: text });
  };

  setSenderId = (senderIdValue: string) => {
    this.setState({ senderIdValue });
  };
  // Customizable Area End
}
