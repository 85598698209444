Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.validationApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteApiMethod = "DELETE";

exports.getOrdersApiEndPoint = "bx_block_shopping_cart/orders";
exports.createOrderItemApiEndPoint = "bx_block_shopping_cart/order_items";
exports.createObjectApiEndPoint = "bx_block_ccavenueintegration2/check_out_encrypt_data";
exports.checkOutDecryptDataEndPoint = "bx_block_ccavenueintegration2/check_out_decrypt_data"
exports.cancelUrlEndPoint = "bx_block_ccavenueintegration2/cancel_url"
exports.successUrl = "/Ccavenueintegration2Success"


exports.merchantId= "2251103";
exports.orderId= "123654789";
exports.currency = "INR";
exports.language = "EN";
exports.country = "India"
exports.additionalParam = "additional Info.";
exports.integrationType = "iframe_normal";
exports.controller = "orders";
exports.action = "ccavenue_request"


exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.successText = "Congratulations! payment completed successfully";
exports.alertMessageText = "Oops! something went wrong";
exports.catalogueIdText = "Catalogue Id";
exports.orderIdText = "Order Id";
exports.currencyText = "Currency"
exports.catalogueText = "Catalogue";
exports.quantityText = "Quantity";
exports.taxableText = "Taxable";
exports.taxableValueText = "Taxable Value";
exports.noOrdersToDisplay = "No orders to display !"
exports.idText = "Id";
exports.priceText = "Price";
exports.actionText = "Action";
exports.totalFeesText = "Total Fees";
exports.totalItemsText = "Total Items";
exports.totalTaxText = "Total Tax";
exports.customerText = "Customer";
exports.amountText = "amount";
exports.parameterValueText = "Parameter Value";
exports.parameterNameText = "Parameter Name";
exports.compulsoryInformationText = "Compulsory Information";
exports.languageText = "Language";
exports.billingInformation = "Billing Information";
exports.billingAddress = "Billing Address";
exports.billingCity = "Billing City";
exports.billingState = "Billing State";
exports.billingZip = "Billing Zip";
exports.billingNameText = "Billing Name"
exports.billingCountry = "Billing Country";
exports.billingTel = "Billing Tel";
exports.billingEmail = "Billing Email";
exports.shippingInformationText = "Shipping Information";
exports.shippingNameText = "Shipping Name";
exports.shippingAddressText = "Shipping Address";
exports.shippingCityText = "Shipping City";
exports.shippingStateText = "Shipping State";
exports.shippingZipText = "Shipping Zip";
exports.shippingCountryText = "Shipping Country";
exports.shippingTelText = "Shipping Tel";
exports.checkOutButtonText = "Checkout";
exports.viewButtonText = "View";
exports.addButtonText = "Add";
exports.closeButtonText = "Close";
exports.cancelButtonText = "Cancel";
exports.deleteButtonText = "Delete"
exports.viewAllOrdersButtonText = "View all orders";
exports.addOrderItemButtonText = "Add Order Item";
// Customizable Area End
