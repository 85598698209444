import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import ShippingSpeedController, {
  Props,
} from "./ShippingSpeedController";
import { MyOrder } from "../../types/types";

export default class ShippingSpeed extends ShippingSpeedController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"lg"}>
        <Box style={{ margin: "2rem"}}>
          <Typography variant="h6">My Cart</Typography>
          <Box>
            <p>Selected Order ID:</p>
            <p>{this.state.selectedOrder?.id}</p>
          </Box>
          <TableContainer component={Paper}>
            <Button variant="contained" onClick={() => this.handleOrdersData()} data-test-id="refresh-table-cart-button">
              Refresh Table Contents
            </Button>
            <Table
              size="small"
              aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Order number</TableCell>
                  <TableCell> Status</TableCell>
                </TableRow>
              </TableHead>
              {this.state.isTableLoading ? <CircularProgress /> : 
                <TableBody>
                  {this.state.orders.map((item: MyOrder, index) => {
                    return (
                      <TableRow
                        data-test-id={"cart-table-row-" + index}
                        key={`item${index}`}
                        onClick={() => this.handleSelectedOrderChange(item)}>
                        <TableCell>
                          {item.id}
                        </TableCell>
                        <TableCell>
                          {item.attributes.easypost_order_status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Box>
        {this.state.selectedShipmentRate && (
          <Box>
            <Typography data-test-id="selected-carrier" variant="body1">{this.state.selectedShipmentRate.carrier}</Typography>
            <Typography data-test-id="selected-rate" variant="body1">{this.state.selectedShipmentRate.rate}</Typography>
          </Box>
        )}
        <FormControl component="fieldset">
          <FormLabel component="legend">Shipping Speed</FormLabel>
          <RadioGroup data-test-id="speed-radio-group" aria-label="gender" name="shipping_speed" onChange={this.handleOrderSelectChange} color="primary">
            {this.state.rates?.map((rate, index) => 
              <FormControlLabel 
                key={rate.id} 
                value={rate.id} 
                data-test-id={"speed-option-" + index}
                control={<Radio />} 
                label={`${rate.est_delivery_days} Day Delivery`} 
              />
            )}
          </RadioGroup>
        </FormControl>
        {this.state.selectedShipmentRate && this.state.selectedOrder && (
          <Box>
            <Button
              size="small" 
              color="secondary" 
              variant="contained"
              data-test-id="place-order-button"
              onClick={() => this.handlePlaceOrder()}
            >
              Place Order
            </Button>
          </Box>
        )}
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputContainer: {
    display: "flex",
    gap: "3rem",
    marginBottom: "2rem"
  },
};
// Customizable Area End

