import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Input,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import Ccavenueintegration2CartItemController, {
  Props,
  configJSON,
} from "./Ccavenueintegration2CartItemController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Ccavenueintegration2CartItem extends Ccavenueintegration2CartItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box sx={webStyle.viewAllButtonContainer}>
            <Button
              data-test-id="btnNavigateToShoppingCartOrders"
              variant="contained"
              color="primary"
              onClick={this.navigateToShoppingCartOrders}>
              {configJSON.viewAllOrdersButtonText}
            </Button>
          </Box>
          <Box sx={webStyle.orderItemBox}>
            <Box sx={webStyle.tableBox}>
              <Typography variant="h6">
                {configJSON.catalogueIdText}:
              </Typography>
              <Input
                data-test-id="inputCatalogueId"
                placeholder={configJSON.catalogueIdText}
                name="cartCatalogueId"
                value={this.state.cartCatalogueId}
                onChange={this.handleChangeInputCartOrders}
              />
            </Box>
            <Box sx={webStyle.tableBox}>
              <Typography variant="h6">{configJSON.quantityText}: </Typography>
              <Input
                data-test-id="inputQuantity"
                placeholder={configJSON.quantityText}
                name="cartQuantity"
                value={this.state.cartQuantity}
                onChange={this.handleChangeInputCartOrders}
              />
            </Box>
            <Box sx={webStyle.tableBox}>
              <Typography variant="h6">{configJSON.taxableText}: </Typography>
              <Checkbox
                data-test-id="btnTaxable"
                checked={this.state.cartTaxable}
                onClick={this.handleToggleTaxable}
              />
            </Box>
            <Box sx={webStyle.tableBox}>
              <Typography variant="h6">
                {configJSON.taxableValueText}:{" "}
              </Typography>
              <Input
                data-test-id="inputTaxableValue"
                placeholder={configJSON.taxableText}
                name="cartTaxableValue"
                value={this.state.cartTaxableValue}
                onChange={this.handleChangeInputCartOrders}
              />
            </Box>
            <Button
              data-test-id="btnAddOrderItem"
              variant="contained"
              color="primary"
              onClick={() => this.createOrderItem(this.state.cartToken)}>
              {configJSON.addOrderItemButtonText}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    );

    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  viewAllButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  orderItemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};
// Customizable Area End