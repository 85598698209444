import React from "react";

import {
  Container,

  // Customizable Area Start
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import Ccavenueintegration2CheckoutController, {
  Props,
  configJSON,
} from "./Ccavenueintegration2CheckoutController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class Ccavenueintegration2Checkout extends Ccavenueintegration2CheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Paper style={webStyle.checkoutPaperContainer}>
            <TableContainer style={webStyle.checkoutTableContainer}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.parameterNameText}</TableCell>
                    <TableCell>{configJSON.parameterValueText}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {configJSON.compulsoryInformationText}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.orderIdText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="orderId"
                        value={this.state.orderId}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.currencyText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="currency"
                        value={this.state.currency}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.amountText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="amount"
                        value={this.state.amount}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.languageText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="language"
                        id="language"
                        value={this.state.language}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {configJSON.billingInformation} :
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingNameText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingName"
                        data-test-id="billingNameInput"
                        value={this.state.billingName}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingAddress}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingAddress"
                        data-test-id="billingAddressInput"
                        value={this.state.billingAddress}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingCity}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingCity"
                        data-test-id="billingCityInput"
                        value={this.state.billingCity}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingState}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingState"
                        data-test-id="billingStateInput"
                        value={this.state.billingState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingZip}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingZip"
                        data-test-id="billingZipInput"
                        value={this.state.billingZip}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingCountry}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingCountry"
                        data-test-id="billingCountryInput"
                        value={this.state.billingCountry}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingTel}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingTel"
                        value={this.state.billingTel}
                        data-test-id="billingTelInput"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.billingEmail}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="billingEmail"
                        data-test-id="billingEmailInput"
                        value={this.state.billingEmail}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {configJSON.shippingInformationText} :
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingNameText}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryName"
                        data-test-id="deliveryNameInput"
                        value={this.state.deliveryName}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingAddressText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryAddress"
                        data-test-id="deliveryAddressInput"
                        value={this.state.deliveryAddress}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingCityText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryCity"
                        data-test-id="deliveryCityInput"
                        value={this.state.deliveryCity}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingStateText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryState"
                        data-test-id="deliveryStateInput"
                        value={this.state.deliveryState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingZipText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryZip"
                        data-test-id="deliveryZipInput"
                        value={this.state.deliveryZip}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingCountryText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryCountry"
                        value={this.state.deliveryCountry}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{configJSON.shippingTelText}:</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        onChange={this.handelInputChange}
                        name="deliveryTel"
                        data-test-id="deliveryTelInput"
                        value={this.state.deliveryTel}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <Button
                        data-test-id="checkOutButton"
                        color="inherit"
                        variant="outlined"
                        onClick={this.handleSubmitCheckout}>
                        {configJSON.checkOutButtonText}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  checkoutPaperContainer: {
    width: "100%",
    overflow: "hidden",
  },
  checkoutTableContainer: {
    maxHeight: "100vh",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
