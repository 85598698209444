// Customizable Area Start

export interface ICustomerData {
  id: number;
  type: string;
  attributes: {
    customer: {
      data: {
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
          full_phone_number: string;
        };
      };
    };
    order_items: {
      data: [
        {
          id: string;
        },
      ];
    };
    address: {
      data: {
        id: string;
        type: string;
        attributes: {
          latitude: string;
          longitude: string;
          address: string;
          address_type: string;
          city: string;
          pincode: number;
          state: string;
          address2: string;
          country: string;
        };
      };
    };
  };
}

interface OrderDataAttributesOrderItem {
  id: string;
  type: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges: string;
    catalogue: {
      data: {
        id: string;
        type: string;
        attributes: {
          sub_category: {
            id: number;
            name: string;
            created_at: string;
            updated_at: string;
          };
          brand: string;
          name: string;
          sku: string;
          description: string;
          manufacture_date: string;
          sale_price: string;
          discount: string;
          category: {
            id: string;
            type: string;
            attributes: {
              id: number;
              name: string;
              created_at: string;
              updated_at: string;
            };
          };
          average_rating: number;
        };
      };
    };
  };
}
interface OrderDataAttributesAddress {
  data: {
    id: string;
    type: string;
    attributes: {
      address: string;
      address_type: string;
      city: string;
      pincode: number;
      state: string;
      address2: string;
      country: string;
    };
  };
}
interface OrderDataAttributesCustomer {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      first_name: string;
      full_phone_number: string;
      last_name: string;
      phone_number: string;
      type: string;
      created_at: string;
      updated_at: string;
    };
  };
}
interface OrderDataAttributes {
  status: string;
  total_fees: number;
  total_items: number;
  total_tax: number;
  ship_rocket_order_id: string;
  ship_rocket_shipment_id: string;
  ship_rocket_awb_code: string;
  customer: OrderDataAttributesCustomer;
  address: OrderDataAttributesAddress;
  order_items: {
    data: OrderDataAttributesOrderItem[];
  };
}
interface OrderData {
  id: string;
  type: string;
  attributes: OrderDataAttributes;
}
interface ShipmentDetailsProducts {
  id: number;
  order_id: number;
  product_id: number;
  name: string;
  sku: string;
  description: string;
  channel_order_product_id: string;
  channel_sku: string;
  price: number;
  cost: number;
  mrp: number;
  quantity: number;
  returnable_quantity: number;
  tax: number;
  status: number;
  net_total: number;
  discount: number;
  channel_category: string;
}
interface ShipmentDetailsData {
  id: number;
  channel_id: number;
  channel_name: string;
  base_channel_code: string;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  customer_address: string;
  customer_address_2: string;
  customer_city: string;
  customer_state: string;
  customer_pincode: string;
  customer_country: string;
  pickup_code: string;
  pickup_location: string;
  pickup_location_id: number;
  pickup_id: string;
  ship_type: string;
  courier_mode: string;
  currency: string;
  country_code: number;
  exchange_rate_usd: number;
  exchange_rate_inr: number;
  state_code: number;
  payment_status: string;
  delivery_code: string;
  total: number;
  total_inr: number;
  total_usd: number;
  net_total: string;
  discount: number;
  status: string;
  channel_created_at: string;
  created_at: string;
  order_date: string;
  updated_at: string;
  products: ShipmentDetailsProducts[];
  shipments: {
    id: number;
    order_id: number;
    order_product_id: number;
    channel_id: number;
    code: string;
    cost: string;
    tax: string;
    awb: string;
    awb_assign_date: string;
    etd: string;
    delivered_date: string;
    quantity: number;
    cod_charges: string;
    courier: string;
    courier_id: number;
    status: string;
    created_at: string;
    updated_at: string;
  };
  pickup_address: {
    id: number;
    pin_code: string;
    pickup_code: string;
    name: string;
    phone: string;
    phone_verified: number;
    address: string;
    address_2: string;
    city: string;
    state: string;
    country: string;
    email: string;
    lat: string;
    long: string;
    new: number;
  };
}

interface TrackingData {
  tracking_data: {
    track_status: number;
    shipment_status: number;
    shipment_track: ShipmentTrack[];
    shipment_track_activities: ShipmentTrackActivity[];
    track_url: string;
    etd: string;
    qc_response: QcResponse;
  };
}

interface ShipmentTrack {
  id: number;
  awb_code: string;
  courier_company_id: number;
  shipment_id: number;
  order_id: number;
  pickup_date: string;
  delivered_date: string;
  weight: string;
  packages: number;
  current_status: string;
  delivered_to: string;
  destination: string;
  consignee_name: string;
  origin: string;
  courier_agent_details: string;
  courier_name: string;
  edd: string;
  pod: string;
  pod_status: string;
}

interface TrackOrderType {
  data: TrackingData[];
}

interface ShipmentTrackActivity {
  date: string;
  status: string;
  activity: string;
  location: string;
  sr_status: string;
  sr_status_label: string;
}

interface QcResponse {
  qc_image: string;
  qc_failed_reason: string;
}

export interface IOrderItem {
  id: string;
  attributes: {
    price: string;
    quantity: string;
    catalogue: {
      data: {
        attributes: {
          name: string;
          sub_category: {
            name: string;
          };
          category: {
            attributes: {
              name: string;
            };
          };
        };
      };
    };
  };
}

export interface MyOrder {
  id: string;
  type: string;
  attributes: {
    isPickupCreated?: boolean;
    shipment?: ShippingLabel | string;
    status: string;
    total_fees: number;
    total_items: number;
    total_tax: number;
    source?: string;
    length?: number;
    breadth?: number;
    weight?: number;
    height?: number;
    is_request_pickup: boolean;
    easypost_shipment?: EasypostShipment;
    easypost_return_shipment?: EasypostReturnShipment;
    easypost_order_status: string;
    customer: {
      data: {
        id: string;
        type: string;
        attributes: {
          activated: boolean;
          country_code: string;
          email: string;
          first_name: string;
          full_phone_number: string;
          last_name: string;
          phone_number?: string;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string;
          unique_auth_id: string;
          user_type: string;
        };
      };
    };
    address: {
      data?: {
        id: string;
        type: string;
        attributes: {
          latitude: string;
          longitude: string;
          address: string;
          address_type: string;
          city: string;
          pincode: number;
          state: string;
          address2: string;
          country: string;
        };
      };
    };
    order_items: {
      data: [
        {
          id: string;
          type: string;
          attributes: {
            price: number;
            quantity: number;
            taxable: boolean;
            taxable_value: number;
            other_charges: string;
            catalogue: {
              data: {
                id: string;
                type: string;
                attributes: {
                  sub_category: {
                    id: number;
                    name: string;
                    created_at: string;
                    updated_at: string;
                    parent_id: string;
                    rank: string;
                  };
                  brand: string;
                  tags: [];
                  reviews: [];
                  name: string;
                  sku: string;
                  description: string;
                  manufacture_date: string;
                  length: string;
                  breadth: string;
                  height: string;
                  stock_qty: string;
                  availability: string;
                  weight: string;
                  price: string;
                  recommended: string;
                  on_sale: string;
                  sale_price: string;
                  discount: string;
                  category: {
                    id: string;
                    type: string;
                    attributes: {
                      id: 1;
                      name: string;
                      dark_icon: string;
                      dark_icon_active: string;
                      dark_icon_inactive: string;
                      light_icon: string;
                      light_icon_active: string;
                      light_icon_inactive: string;
                      rank: string;
                      created_at: string;
                      updated_at: string;
                      selected_sub_categories: string;
                    };
                  };
                  images: string;
                  average_rating: number;
                  catalogue_variants: [];
                };
              };
            };
          };
        },
      ];
    };
    return_order?: ReturnResponse
  };
}

export interface EasypostShipment {
  id: number
  shipment_id: string
  rate_id: string
  tracking_code: string
  pickup_id: string
  is_pickup_created?: boolean
  created_at: string
  updated_at: string
  merchant_id?: number
  rate: Rate
  pickup?: Pickup
  label_url?: string
}

export interface Pickup {
  id: string
  object: string
  created_at: string
  updated_at: string
  mode: string
  status: string
  reference?: string
  min_datetime?: string
  max_datetime?: string
  is_account_address: boolean
  instructions?: string
  messages: string[]
  confirmation?: string
  address: Address
  carrier_accounts: string[]
  pickup_rates: string[]
}

export interface Address {
  id: string
  object: string
  created_at: string
  updated_at: string
  name: string
  company: string
  street1: string
  street2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  email: string
  mode: string
  carrier_facility: string
  residential: boolean
  federal_tax_id: string
  state_tax_id: string
  verifications: Verifications
}

export interface Verifications {
  zip4: Zip4
  delivery: Delivery
}

export interface Zip4 {
  success: boolean
  errors: string[]
  details: string
}

export interface Delivery {
  success: boolean
  errors: string[]
  details: Details
}

export interface Details {
  latitude: number
  longitude: number
  time_zone: string
}


export interface Rate {
  id: string
  object: string
  created_at: string
  updated_at: string
  mode: string
  service: string
  carrier: string
  rate: string
  currency: string
  retail_rate: string
  retail_currency: string
  list_rate: string
  list_currency: string
  billing_type: string
  delivery_days: number
  delivery_date: string
  delivery_date_guaranteed: boolean
  est_delivery_days: number
  shipment_id: string
  carrier_account_id: string
  label_url?: string
}

export interface ICustomerDataThree {
  id: string;
  type: string;
  attributes: {
    status: string;
    total_fees: number;
    total_items: number;
    total_tax: number;
    source: string;
    length: number;
    breadth: number;
    weight: number;
    height: number;
    ship_rocket_order_id: string;
    ship_rocket_shipment_id: string;
    ship_rocket_awb_code: string;
    ship_rocket_label_url: string;
    ship_rocket_manifest_url: string;
    ship_rocket_invoice_url: string;
    is_request_pickup: boolean;
    customer: {
      data: {
        id: string;
        type: string;
        attributes: {
          activated: boolean;
          country_code: string;
          email: string;
          first_name: string;
          full_phone_number: string;
          last_name: string;
          phone_number: string;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string;
          unique_auth_id: string;
        };
      };
    };
    address: {
      data: {
        id: string;
        type: string;
        attributes: {
          latitude: string;
          longitude: string;
          address: string;
          address_type: string;
          city: string;
          pincode: number;
          state: string;
          address2: string;
          country: string;
        };
      };
    };
    order_items: {
      data: [];
    };
  };
}

interface ReusableCustomerData {
  id: string;
  type: string;
  attributes: {
    order_items: {
      data: [];
    };
    ship_rocket_order_id: string;
    ship_rocket_awb_code: string;
    status: string;
    customer: {
      data: {
        attributes: {
          name: string;
          first_name: string;
          last_name: string;
          email: string;
          full_phone_number: string;
          ship_rocket_order_id: string;
          status: string;
          sub_category: {
            name: string;
          };
          category: {
            attributes: {
              name: string;
            };
          };
        };
      };
    };
  };
}

export {
  OrderData,
  OrderDataAttributes,
  OrderDataAttributesAddress,
  OrderDataAttributesCustomer,
  OrderDataAttributesOrderItem,
  ShipmentDetailsData,
  TrackOrderType,
  ShipmentTrack,
  ShipmentTrackActivity,
  ReusableCustomerData,
};


export interface ReturnResponse {
  status: boolean
  message: string
  shipment_id: string
  shipping_label: ShippingLabel
  easypost_return_shipment: EasypostReturnShipment
}

export interface ShippingLabel {
  object: string
  id: string
  created_at: string
  updated_at: string
  date_advance: number
  integrated_form: string
  label_date: string
  label_resolution: number
  label_size: string
  label_type: string
  label_file_type: string
  label_url: string
  label_pdf_url: string
  label_zpl_url: string
  label_epl2_url: string
  label_file: string
}

export interface EasypostReturnShipment {
  id: number
  shipment_id: string
  rate_id: string
  tracking_code: string
  pickup_id: string
  is_pickup_created: boolean | string
  return_user_id: number
  order_id: number
  created_at: string
  updated_at: string
  label_url?: string
  pickup?: Pickup
  rate?: Rate
}

export interface TrackingHistory {
  object: string
  message: string
  description: string
  status: string
  status_detail: string
  datetime: string
  source: string
  carrier_code: string
  tracking_location: TrackingLocation
}

export interface TrackingLocation {
  object: string
  city?: string
  state?: string
  country: string
  zip?: string
}

export enum ORDER_STATUS {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  SCHEDULED = "scheduled",
  DELIVERED = "delivered",
  SHIPPED = "shipped",
  PLACED = "placed",
  RETURN_IN_PROGRESS = "return_in_progress",
  ON_GOING = "on_going",
  REFUND_SUCCESSFULL = "refund_successfull",
  SHIPMENT_IN_PROGRESS = "shipment_in_progress",
  UNKNOWN = "unknown",
  PRE_TRANSIT = "pre_transit",
  IN_TRANSIT = "in_transit",
  OUT_FOR_DELIVERY = "out_for_delivery",
  RETURN_SHIPPED = "return_shipped",
  RETURN_PRE_TRANSIT = "return_pre_transit",
  RETURN_IN_TRANSIT = "return_in_transit",
  RETURN_OUT_FOR_DELIVERY = "return_out_for_delivery",
  RETURN_DELIVERED = "return_delivered",
}

export interface ReturnBalanceResponse {
  total_rate_amount: number
  is_sufficient: boolean
}

export interface OrderMeta {
  current_page: number
  next_page: number
  prev_page: number | null
  total_pages: number
  total_count: number
}

export interface AnalyticsResponse {
  revenue: number
  orders: number
  shipment_labels: number
  pickups: number
  deliveries: number
  returned: number
}

export interface AnalyticsOrder {
  date: string
  orders_count: number
}

export interface StripeConnectData {
  id: number
  customer_id: string
  card_id: string
  bank_id: string
  linked_with_easypost: boolean
  account_id: number
  balance: number
}

export interface UserInfo {
  id: number
  easypost_production_api_key: string
  easypost_test_api_key: string
  account_id: number
}

 // Customizable Area End