
export function ResponseMessageHandler(input: {
  jsonRes: { error?: string; errors?: [key: string] };
  errorJson?: string;
  onSuccess: () => void;
  onFail: () => void;
}) {
  const {jsonRes, onSuccess, onFail, errorJson} = input;

  if (jsonRes && !jsonRes.error && !jsonRes.errors) {
    onSuccess();
  }

  if (jsonRes?.error || errorJson || jsonRes.errors) {
    onFail();
  }
}
