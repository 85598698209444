Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.sendMessageAPiEndPoint = "bx_block_kaleyra2/send_sms/send_first_sms";
exports.sendMessgaeAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "kaleyra2";
exports.labelBodyText = "kaleyra2 Body";
exports.successSubmit = "succesfully Submitted"
exports.apyKeySubmit = "API key cannot be blank"
exports.sidSubmit = "SID cannot be blank"
exports.messageSubmit = "Message cannot be blank"
exports.phoneNumberSubmit = "Phone number cannot be blank or in an invalid format"
exports.senderIdSubmit = "Sender ID cannot be blank"
exports.senderSubmit = "Successfully submitted"

exports.btnExampleTitle = "CLICK ME";
exports.kayleraSubmit = "SUBMIT";
// Customizable Area End

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End